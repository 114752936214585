// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    fontWeight: 500,
    fontFamily: [ 
      'Inter', 'Roboto', 'sans-serif',
    ].join(','),
    marginBottom: 8,
  },
  input: {
    height: 48,
    outline: 'none',
  },
}))

export default useStyles