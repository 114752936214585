import React from 'react'

// STYLES
import useStyles from './companiesUseStyles'

// DATA
import dataCompanies from './dataCompanies'

// MUIS
import Container from '@mui/material/Container'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Box from '@mui/material/Box'

const Companies = () => {
  const classes = useStyles()

  return (
    <Container maxWidth={false} className={classes.root}>
      {/* LIST COMPANIES */}
      <List className={classes.listCompanies}>
        {dataCompanies && dataCompanies.map((item, index) => (
          <ListItem
            key={index}
            className={classes.listCompaniesItem}
            sx={{
              width: item.width
            }}
          >
            {/* IMAGE */}
            <Box
              component='img'
              src={item.src}
              alt={item.alt}
              sx={{
                width: item.width
              }}
            />
          </ListItem>
        ))}
      </List>
    </Container>
  )
}

export default Companies