// MUI
import { DataGrid } from '@mui/x-data-grid'
import { styled } from '@mui/material/styles'

// CONSTAN
import {colorsLanding} from 'constants/colors'

const CustomDataGrid = styled(
  ({
    className,
    headerHeight=73 ,
    ...props
  })  => (
    <DataGrid 
      headerHeight={headerHeight} 
      className={className} 
      isRowSelectable={(params) => false}
      {...props} 
    />
  )
)(({theme})=>({
  border : '3px solid black',

  // HEADER
  '& .MuiDataGrid-columnHeader:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-sortIcon' : {
    display : 'none'
  },
  '& .MuiDataGrid-iconSeparator' : {
    display : 'none'
  },
  '& .MuiDataGrid-columnHeaderTitle' : {
    fontSize : 24,
    fontWeight : 700
  },
  '& .left-header' : {
    backgroundColor : colorsLanding.cyan,
    borderRight : 'solid 3px black'
  },
  '& .center-header' : {
    borderRight : 'solid 3px black'
  },
  '& .MuiIconButton-root' : {
    display : 'none'
  },
  '& .MuiDataGrid-columnHeader--sortable' : {
    cursor : 'default'
  },

  // ROWS
  '& .MuiDataGrid-row' : {
    height : 64,
    maxHeight : '64px !important',
  },
  '& .left-cell' : {
    backgroundColor : colorsLanding.cyan,
    borderTop : 'solid 3px black',
    borderRight : 'solid 3px black',
  },
  '& .center-cell' : {
    backgroundColor : 'white',
    borderTop : 'solid 3px black',
    borderRight : 'solid 3px black',
    justifyContent : 'center',
  },
  '& .right-cell' : {
    backgroundColor : 'white',
    borderTop : 'solid 3px black',
    justifyContent : 'center',
  },
  '& .MuiDataGrid-cellContent' : {
    fontSize : 16,
    fontWeight : 400,
    fontFamily : [ 
      'Inter', 'Roboto', 'sans-serif',
    ].join(','),
  },

  // CELL
  '& .MuiDataGrid-cell' : {
    outline : 'none',
    maxHeight : '64px !important',
  },
  '& .MuiDataGrid-cell:focus' : {
    outline : 'none'
  },
  '& .MuiDataGrid-cell:hover' : {
    backgroundColor : 'none'
  }
}))

export default CustomDataGrid