// ASSETS
import LandingIconMaintance from 'assets/images/icons/landing-icon-maintance.svg'
import LandingIconInspections from 'assets/images/icons/landing-icon-inspections.svg'
import LandingIconQualityControl from 'assets/images/icons/landing-icon-quality-control.svg'
import LandingIconSafety from 'assets/images/icons/landing-icon-safety.svg'

const dataBenefits = [
  {
    title: 'Maintance',
    description: 'Submit maintenance forms from anywhere with Worxid. Build custom digital forms for maintenance checks to complete on tablets and mobile devices in the field.',
    srcIcon: LandingIconMaintance
  },
  {
    title: 'Safety',
    description: 'Perform job safety analysis forms and checklists on mobile devices. Submit and log safety information in your backend systems. Improve safety standards by tracking accurate workplace safety data.',
    srcIcon: LandingIconSafety
  },
  {
    title: 'Inspections',
    description: 'Build custom inspection forms to complete on mobile devices with Worxid. Use smartphones and tablets in the field to capture inspection information, and submit inspection reports instantly.',
    srcIcon: LandingIconInspections
  },
  {
    title: 'Quality Control',
    description: 'Mobile forms allow staff on the floor or in the field to input & track data from quality checks. Build custom forms for recurring quality reviews & checklists to be completed on smartphones or tablets.',
    srcIcon: LandingIconQualityControl
  }
]

export default dataBenefits