// COMPONENTS
import Hero from './Hero'
import Information from './Information/Information'
import GridFeatures from './GridFeatures/GridFeatures'

// FEATURES DATA
import { dataFeatures } from './dataFeatures'

// LANDING COMPONENT
import StartNow from 'components/LandingStartNow'

const LandingFeatures = () => {
  return (
    <>
      {/* HERO */}
      <Hero />

      {/* INFORMATION TOP */}
      <Information/>

      {/* FEATURES TOP */}
      <GridFeatures listData={dataFeatures.slice(0,5)}/>

      {/* INFORMATION BOTTOM */}
      <Information/>

      {/* FEATURES BOTTOM */}
      <GridFeatures listData={dataFeatures.slice(5,10)}/>

      {/* START NOW */}
      <StartNow/>
    </>
  )
}

export default LandingFeatures