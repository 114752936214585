// MUIS
import { Box, Typography } from '@mui/material'

// ASSETS
import CheckIcon from 'assets/images/icons/landing-pricing-check.svg'

// STYLES
const checklistWidth = {
  width  : 30
}

const textSize = {
  fontSize : 16, 
  fontWeight : 500, 
  fontFamily : [ 
    'Inter', 'Roboto', 'sans-serif',
  ].join(',')
}

const dollarSize = {
  fontSize : 30, 
  fontWeight : 700
}

// CAPACITY COLUMNS
const capacityColumns = [
  {
    field: 'first',
    headerName : 'Capacity',
    headerClassName: 'left-header',
    cellClassName : 'left-cell',
    headerAlign: 'left',
    minWidth: 250,
    flex : 2,
    sortable : false
  },
  {
    field: 'center',
    headerName : 'Professional',
    cellClassName : 'center-cell',
    headerClassName: 'center-header',
    headerAlign: 'center',
    minWidth: 200,
    flex : 1,
    sortable : false,
    renderCell : (params)=>(
      params.value && <Box component='img'
        src={params.value}
        alt='Worx Logo' 
        sx={checklistWidth}
      />
    )
  },
  {
    field: 'last',
    headerName : 'Enterprise',
    cellClassName : 'right-cell',
    headerAlign: 'center',
    minWidth: 200,
    flex : 1,
    sortable : false,
    renderCell : (params)=>(
      params.value && <Box component='img'
        src={params.value}
        alt='Worx Logo' 
        sx={checklistWidth}
      />
    )
  },
]

// CAPACITY ROWS
const capacityRows = [
  {
    id: 1,
    first: 'Unlimited Form Submissions',
    center: CheckIcon,
    last : CheckIcon
  },
  {
    id: 2,
    first: 'Unlimited Data Storage',
    center: CheckIcon,
    last : CheckIcon
  },
]

// SUBMISSION FORMAT COLUMNS
const submissionFormatColumns = [
  {
    field: 'first',
    headerName : 'Submission Formats',
    headerClassName: 'left-header',
    cellClassName : 'left-cell',
    headerAlign: 'left',
    minWidth: 250,
    flex : 2,
    sortable : false
  },
  {
    field: 'center',
    headerName : 'Professional',
    cellClassName : 'center-cell',
    headerClassName: 'center-header',
    headerAlign: 'center',
    minWidth: 250,
    flex : 1,
    sortable : false,
    renderCell : (params)=>(
      params.value && <Box component='img'
        src={params.value}
        alt='Worx Logo' 
        sx={checklistWidth}
      />
    )
  },
  {
    field: 'last',
    headerName : 'Enterprise',
    cellClassName : 'right-cell',
    headerAlign: 'center',
    minWidth: 250,
    flex : 1,
    sortable : false,
    renderCell : (params)=>(
      params.value && <Box component='img'
        src={params.value}
        alt='Worx Logo' 
        sx={checklistWidth}
      />
    )
  },
]

// SUBMISSION FORMAT ROWS
const submissionFormatRows = [
  {
    id: 1,
    first: 'Plain Text',
    center: CheckIcon,
    last : CheckIcon
  },
  {
    id: 2,
    first: 'Images',
    center: CheckIcon,
    last : CheckIcon
  },
  {
    id: 3,
    first: 'PDF',
    center: CheckIcon,
    last : CheckIcon
  },
  {
    id: 4,
    first: 'Microsoft Excel',
    center: null,
    last : CheckIcon
  },
  {
    id: 5,
    first: 'JSON',
    center: null,
    last : CheckIcon
  },
  {
    id: 6,
    first: 'XML',
    center: null,
    last : CheckIcon
  },
]

// SUBMISSION DESTINATION COLUMNS
const submissionDestinationColumns = [
  {
    field: 'first',
    headerName : 'Submission Destinations',
    headerClassName: 'left-header',
    cellClassName : 'left-cell',
    headerAlign: 'left',
    minWidth: 200,
    flex : 2,
    sortable : false
  },
  {
    field: 'center',
    headerName : 'Professional',
    cellClassName : 'center-cell',
    headerClassName: 'center-header',
    headerAlign: 'center',
    minWidth: 200,
    flex : 1,
    sortable : false,
    renderCell : (params)=>(
      params.value && <Box component='img'
        src={params.value}
        alt='Worx Logo' 
        sx={checklistWidth}
      />
    )
  },
  {
    field: 'last',
    headerName : 'Enterprise',
    cellClassName : 'right-cell',
    headerAlign: 'center',
    minWidth: 200,
    flex : 1,
    sortable : false,
    renderCell : (params)=>(
      params.value && <Box component='img'
        src={params.value}
        alt='Worx Logo' 
        sx={checklistWidth}
      />
    )
  },
]

// SUBMISSION DESTINATION ROWS
const submissionsDestinationRows = [
  {
    id: 1,
    first: 'Email',
    center: CheckIcon,
    last : CheckIcon
  },
  {
    id: 2,
    first: 'Amazon S3',
    center: CheckIcon,
    last : CheckIcon
  },
  {
    id: 3,
    first: 'Microsoft OneDrive',
    center: CheckIcon,
    last : CheckIcon
  },
  {
    id: 4,
    first: 'Dropbox',
    center: CheckIcon,
    last : CheckIcon
  },
  {
    id: 5,
    first: 'Box',
    center: CheckIcon,
    last : CheckIcon
  },
  {
    id: 6,
    first: 'Evernote',
    center: CheckIcon,
    last : CheckIcon
  },
  {
    id: 7,
    first: 'Slack',
    center: CheckIcon,
    last : CheckIcon
  },
  {
    id: 8,
    first: 'SMS',
    center: null,
    last : CheckIcon
  },
  {
    id: 9,
    first: 'Zapier',
    center: null,
    last : CheckIcon
  },
  {
    id: 10,
    first: 'Salesforce',
    center: null,
    last : CheckIcon
  },
  {
    id: 11,
    first: 'Sharefile',
    center: null,
    last : CheckIcon
  },
  {
    id: 12,
    first: 'Geotab',
    center: null,
    last : CheckIcon
  },
  {
    id: 13,
    first: 'SQL',
    center: null,
    last : CheckIcon
  },
]

// LIVE DATA SOURCE COLUMNS
const liveDataColumns = [
  {
    field: 'first',
    headerName : 'Live Data Sources',
    headerClassName: 'left-header',
    cellClassName : 'left-cell',
    headerAlign: 'left',
    minWidth: 200,
    flex : 2,
    sortable : false
  },
  {
    field: 'center',
    headerName : 'Professional',
    cellClassName : 'center-cell',
    headerClassName: 'center-header',
    headerAlign: 'center',
    minWidth: 200,
    flex : 1,
    sortable : false,
    renderCell : (params)=>(
      params.value && <Box component='img'
        src={params.value}
        alt='Worx Logo' 
        sx={checklistWidth}
      />
    )
  },
  {
    field: 'last',
    headerName : 'Enterprise',
    cellClassName : 'right-cell',
    headerAlign: 'center',
    minWidth: 200,
    flex : 1,
    sortable : false,
    renderCell : (params)=>(
      params.value && <Box component='img'
        src={params.value}
        alt='Worx Logo' 
        sx={checklistWidth}
      />
    )
  },
]

// // LIVE DATA SOURCE ROWS
const liveDataRows = [
  {
    id: 1,
    first: 'Google Drive',
    center: null,
    last : CheckIcon
  },
  {
    id: 2,
    first: 'SQL',
    center: null,
    last : CheckIcon
  },
]

// WORKFLOW COLUMNS
const workflowColumns = [
  {
    field: 'first',
    headerName : 'WorkFlow',
    headerClassName: 'left-header',
    cellClassName : 'left-cell',
    headerAlign: 'left',
    minWidth: 200,
    flex : 2,
    sortable : false
  },
  {
    field: 'center',
    headerName : 'Professional',
    cellClassName : 'center-cell',
    headerClassName: 'center-header',
    headerAlign: 'center',
    minWidth: 200,
    flex : 1,
    sortable : false,
    renderCell : (params)=>(
      params.value && <Box component='img'
        src={params.value}
        alt='Worx Logo' 
        sx={checklistWidth}
      />
    )
  },
  {
    field: 'last',
    headerName : 'Enterprise',
    cellClassName : 'right-cell',
    headerAlign: 'center',
    minWidth: 200,
    flex : 1,
    sortable : false,
    renderCell : (params)=>(
      params.value && <Box component='img'
        src={params.value}
        alt='Worx Logo' 
        sx={checklistWidth}
      />
    )
  },
]

// WORKFLOW ROWS
const workflowRows = [
  {
    id: 1,
    first: 'Conditional Destinations',
    center: CheckIcon,
    last : CheckIcon
  },
  {
    id: 2,
    first: 'User Groups',
    center: CheckIcon,
    last : CheckIcon
  },
  {
    id: 3,
    first: 'Web Forms',
    center: null,
    last : CheckIcon
  },
  {
    id: 4,
    first: 'Dispatch',
    center: null,
    last : CheckIcon
  },
]

// ANALITYC COLUMNS
const analyticColumns = [
  {
    field: 'first',
    headerName : 'Analytics / Reporting',
    headerClassName: 'left-header',
    cellClassName : 'left-cell',
    headerAlign: 'left',
    minWidth: 200,
    flex : 2,
    sortable : false
  },
  {
    field: 'center',
    headerName : 'Professional',
    cellClassName : 'center-cell',
    headerClassName: 'center-header',
    headerAlign: 'center',
    minWidth: 200,
    flex : 1,
    sortable : false,
    renderCell : (params)=>(
      params.value && <Box component='img'
        src={params.value}
        alt='Worx Logo' 
        sx={checklistWidth}
      />
    )
  },
  {
    field: 'last',
    headerName : 'Enterprise',
    cellClassName : 'right-cell',
    headerAlign: 'center',
    minWidth: 200,
    flex : 1,
    sortable : false,
    renderCell : (params)=>(
      params.value !== 'Specific Plans' ? <Box component='img'
        src={params.value}
        alt='Worx Logo' 
        sx={{width : 30}}
      /> : <Typography sx={textSize}>{params.value}</Typography>
    )
  },
]

// ANALYTIC ROWS
const analyticRows = [
  {
    id: 1,
    first: 'Activity Dashboard',
    center: CheckIcon,
    last : CheckIcon
  },
  {
    id: 2,
    first: 'Weekly Reports',
    center: CheckIcon,
    last : CheckIcon
  },
  {
    id: 3,
    first: 'Customizable Dashboards',
    center: null,
    last : 'Specific Plans'
  }
]

// SECURITY COLUMNS
const securityColumns = [
  {
    field: 'first',
    headerName : 'Security',
    headerClassName: 'left-header',
    cellClassName : 'left-cell',
    headerAlign: 'left',
    minWidth: 200,
    flex : 2,
    sortable : false
  },
  {
    field: 'center',
    headerName : 'Professional',
    cellClassName : 'center-cell',
    headerClassName: 'center-header',
    headerAlign: 'center',
    minWidth: 200,
    flex : 1,
    sortable : false,
    renderCell : (params)=>(
      params.value && <Box component='img'
        src={params.value}
        alt='Worx Logo' 
        sx={checklistWidth}
      />
    )
  },
  {
    field: 'last',
    headerName : 'Enterprise',
    cellClassName : 'right-cell',
    headerAlign: 'center',
    minWidth: 200,
    flex : 1,
    sortable : false,
    renderCell : (params)=>(
      params.value && <Box component='img'
        src={params.value}
        alt='Worx Logo' 
        sx={checklistWidth}
      />
    )
  },
]

// SECURITY ROWS
const securityRows = [
  {
    id: 1,
    first: 'On-device Encryption (iOS)',
    center: CheckIcon,
    last : CheckIcon
  },
  {
    id: 2,
    first: 'In-transit Data Encryption',
    center: CheckIcon,
    last : CheckIcon
  },
  {
    id: 3,
    first: 'HTTPS, TLS, SFTP',
    center: null,
    last : CheckIcon
  },
  {
    id: 4,
    first: 'Active Directory Single Sign-On (SSO)',
    center: null,
    last : CheckIcon
  },
]

// CUSTOMIZATION COLUMNS
export const customizationColumns = [
  {
    field: 'first',
    headerName : 'Customization',
    headerClassName: 'left-header',
    cellClassName : 'left-cell',
    headerAlign: 'left',
    minWidth: 200,
    flex : 2,
    sortable : false
  },
  {
    field: 'center',
    headerName : 'Professional',
    cellClassName : 'center-cell',
    headerClassName: 'center-header',
    headerAlign: 'center',
    minWidth: 200,
    flex : 1,
    sortable : false,
    renderCell : (params)=>(
      params.value && <Box component='img'
        src={params.value}
        alt='Worx Logo' 
        sx={checklistWidth}
      />
    )
  },
  {
    field: 'last',
    headerName : 'Enterprise',
    cellClassName : 'right-cell',
    headerAlign: 'center',
    minWidth: 200,
    flex : 1,
    sortable : false,
    renderCell : (params)=>(
      params.value !== '$' ? <Box component='img'
        src={params.value}
        alt='Worx Logo' 
        sx={{width : 30}}
      /> : <Typography sx={dollarSize}>{params.value}</Typography>
    )
  },
]

// CUSTOMIZATION ROWS
const customizationRows = [
  {
    id: 1,
    first: 'Custom PDF & Word Templates',
    center: CheckIcon,
    last : CheckIcon
  },
  {
    id: 2,
    first: 'Custom Branding',
    center: CheckIcon,
    last : CheckIcon
  },
  {
    id: 3,
    first: 'API Access',
    center: null,
    last : '$'
  },
  {
    id: 4,
    first: 'White Label App',
    center: null,
    last : '$'
  },
  {
    id: 5,
    first: 'Regional Hosting',
    center: null,
    last : '$'
  },
  {
    id: 6,
    first: 'On-Premise Hosting',
    center: null,
    last : '$'
  },
]

// SUPPORT COLUMNS
const supportColumns = [
  {
    field: 'first',
    headerName : 'Support',
    headerClassName: 'left-header',
    cellClassName : 'left-cell',
    headerAlign: 'left',
    minWidth: 200,
    flex : 2,
    sortable : false
  },
  {
    field: 'center',
    headerName : 'Professional',
    cellClassName : 'center-cell',
    headerClassName: 'center-header',
    headerAlign: 'center',
    minWidth: 200,
    flex : 1,
    sortable : false,
    renderCell : (params)=>(
      params.value !== '$' ? <Box component='img'
        src={params.value}
        alt='Worx Logo' 
        sx={{width : 30}}
      /> : <Typography sx={dollarSize}>{params.value}</Typography>
    )
  },
  {
    field: 'last',
    headerName : 'Enterprise',
    cellClassName : 'right-cell',
    headerAlign: 'center',
    minWidth: 200,
    flex : 1,
    sortable : false,
    renderCell : (params)=>(
      params.value !== '$' ? <Box component='img'
        src={params.value}
        alt='Worx Logo' 
        sx={{width : 30}}
      /> : <Typography sx={dollarSize}>{params.value}</Typography>
    )
  },
]

// SUPPORT ROWS
const supportRows = [
  {
    id: 1,
    first: 'Online Resource Library',
    center: CheckIcon,
    last : CheckIcon
  },
  {
    id: 2,
    first: 'Onboarding Assistance',
    center: CheckIcon,
    last : CheckIcon
  },
  {
    id: 3,
    first: 'Email, Live Chat, & Phone Support',
    center: CheckIcon,
    last : CheckIcon
  },
  {
    id: 4,
    first: 'White Glove Form Management',
    center: '$',
    last : '$'
  }
]

export const listData = [
  {
    id : 1,
    height : 207,
    column : capacityColumns,
    row : capacityRows
  },
  {
    id : 2,
    height : 463,
    column : submissionFormatColumns,
    row : submissionFormatRows
  },
  {
    id : 3,
    height : 911,
    column : submissionDestinationColumns,
    row : submissionsDestinationRows
  },
  {
    id : 4,
    height : 207,
    column : liveDataColumns,
    row : liveDataRows
  },
  {
    id : 5,
    height : 335,
    column : workflowColumns,
    row : workflowRows
  },
  {
    id : 6,
    height : 271,
    column : analyticColumns,
    row : analyticRows
  },
  {
    id : 7,
    height : 335,
    column : securityColumns,
    row : securityRows
  },
  {
    id : 8,
    height : 463,
    column : customizationColumns,
    row : customizationRows
  },
  {
    id : 9,
    height : 335,
    column : supportColumns,
    row : supportRows
  },
]