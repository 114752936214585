import React from 'react'
import { useNavigate } from 'react-router-dom'

// STYLES
import useStyles from './startNowUseStyles'

// MUIS
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// COMPONENTS
import LandingButtonContained from 'components/LandingButton/LandingButton'


const StartNow = () => {
  const classes = useStyles()
  const navigate = useNavigate()

  const handleButtonClick = () => {
    navigate('#')
  }

  return (
    <Container maxWidth={false} className={classes.root}>
      {/* TITLE */}
      <Typography variant='h3' className={classes.title}>
        Start now to make it easier for your company to work
      </Typography>

      <Box className={classes.buttonWrap}>
        <LandingButtonContained
          className={classes.buttonCustom}
          onClick={handleButtonClick}
        >
          Build Your Account
        </LandingButtonContained>
      </Box>
    </Container>
  )
}

export default StartNow