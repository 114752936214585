// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  mainContainer : {
    width : '100%',
    height : '100%',
    display : 'flex',
    flexDirection : 'column',
    alignItems : 'center',
  },
  gridContainer : {
    width : '100%',
    maxWidth : 1220,
    marginBottom : 46,
  },
  title : {
    fontSize : 40,
    fontWeight : 700,
    marginTop : 120,
  },
  value : {
    fontSize : 16,
    fontWeight : 400,
    fontFamily  : [ 
      'Inter', 'Roboto', 'sans-serif',
    ].join(','),
    marginTop : 24,
    marginBottom : 100,
  },
  buttonContainer : {
    width : '100%',
    maxWidth : 1245,
    marginBottom : 120,
    marginTop : -32,
    display : 'flex'
  },
  button : {
    maxWidth : 302,
    width : '100%',
    marginLeft : '50%',
    marginRight : '25%'
  }
}))

export default useStyles