// STYLES
import useStyles from './comparisonUseStyles'

// MUIS
import { Box, Typography } from '@mui/material'

// COMPONENT
import DataGrid from '../DataGrid/DataGrid'
import LandingButton from 'components/LandingButton/LandingButton'

// COMPARISON DATA
import {listData} from './dataComparisons'

const Comparison = () => {
  const classes = useStyles()
  
  return (
    <Box className={classes.mainContainer}>

      {/* TITLE */}
      <Typography align='center' className={classes.title}>
        Here’s how we compare
      </Typography>
      <Typography align='center' className={classes.value}>
        So that you can choose exactly what your company needs.
      </Typography>

      {/* LIST COMPARISON */}
      {listData && listData.map((item,index)=>(
        <Box key={index} sx={{height : item.height}} className={classes.gridContainer}>
          <DataGrid
            rows={item.row} 
            columns={item.column}
            hideFooter
            disableColumnMenu
          />
        </Box>
      ))}
      
      {/* BUTTON FREE TRIAL */}
      <Box className={classes.buttonContainer}>
        <LandingButton
          className={classes.button}
          color='primary'
          variant='contained'
        >
          Free 30-Day Trial
        </LandingButton>
      </Box>
      
    </Box>
  )
}

export default Comparison