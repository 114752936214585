// ASSSETS
import LandingIconCoin1 from 'assets/images/icons/landing-icon-coin-1.svg'
import LandingIconCoin2 from 'assets/images/icons/landing-icon-coin-2.svg'
import LandingIconCoin3 from 'assets/images/icons/landing-icon-coin-3.svg'
import LandingIconCoin4 from 'assets/images/icons/landing-icon-coin-4.svg'
import LandingIconCoin5 from 'assets/images/icons/landing-icon-coin-5.svg'

export const dataIcons = [
  {
    src: LandingIconCoin1,
    style: {
      left: '78.3px',
      bottom: '-111.81px',
      top: 'unset',
      right: 'unset'
    }
  },
  {
    src: LandingIconCoin2,
    style: {
      top: '38.16px',
      right: '253.8px',
      bottom: 'unset',
      left: 'unset'
    }
  },
  {
    src: LandingIconCoin3,
    style: {
      bottom: '-15px',
      right: '387.88px',
      top: 'unset',
      left: 'unset'
    }
  },
  {
    src: LandingIconCoin4,
    style: {
      bottom: '27.6px',
      right: '-140.34px',
      top: 'unset',
      left: 'unset'
    }
  },
  {
    src: LandingIconCoin5,
    style: {
      top: '75px',
      left: '177px',
      bottom: 'unset',
      right: 'unset'
    }
  }
]