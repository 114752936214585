// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  textField : {
    '& .MuiOutlinedInput-root' : {
      height : 43,
      border : 'solid 3px black',
      borderRadius : 0,
    },
    '& .MuiOutlinedInput-notchedOutline' : {
      border : 'none'
    },
    '& .MuiFormControl-root'  :{
      width : '100%'
    }
  },
  textFieldContainer : {
    marginBottom : 32,
  }
}))

export default useStyles
