// COMPONENTS
import Comparison from './Comparison/Comparison'
import Hero from './Hero/Hero'
import Plans from './Plans/Plans'

const LandingPricing = () => {
  return (
    <>
      <Hero/>
      <Plans/>
      <Comparison/>
    </>
  )
}

export default LandingPricing