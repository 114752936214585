// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  closingContainer : {
    padding : '0px 323px',
    width : '100%',
    height : '100%',
    marginBottom : 120
  },
  accordionDesc : {
    fontFamily  : [ 
      'Inter', 'Roboto', 'sans-serif',
    ].join(','),
    fontSize : 16,
    fontWeight : 400,
    whiteSpace : 'pre-wrap'
  },
  contactUsText : {
    marginBottom : 32,
    marginTop : 80,
    fontSize : 16,
    fontWeight : 600,
    fontFamily : [ 
      'Inter', 'Roboto', 'sans-serif',
    ].join(','),
  },
}))

export default useStyles
