// COLORS
import { colorsLanding } from 'constants/colors'

// MUIS STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: colorsLanding.cyan,
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    padding: '129px 60px 152px 140px'
  },
  contentWrap: {
    alignItems: 'center'
  },
  descriptionWrap: {
    maxWidth: 622,
    width: '100%'
  },
  heroImage: {
    width: '100%',
  },
  heroTitle: {
    fontSize: 56,
    fontWeight: 700,
    lineHeight: 1.5,
    marginBottom: 40,
    letterSpacing: '0.0015em'
  },
  heroDescription: {
    fontFamily: ['Inter', 'Roboto', 'sans-serif'].join(','),
    maxWidth: 519,
    width: '100%',
    marginBottom: 56,
    letterSpacing: '0.15px'
  },
  heroButton: {
    padding: '10.5px 50px',
    fontSize: 18,
    lineHeight: 1.5,

  }
}))

export default useStyles