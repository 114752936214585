import { useEffect, useState } from 'react'

// STYLES
import useStyles from './landingRulesUseStyles'

// MUIS
import { Box,} from '@mui/material'

// SERVICES
import { getLandingRulesAPI, apiEndPointList } from 'services/landing'

// REACT ROUTER
import { useLocation } from 'react-router-dom'

// COMPONENTS
import Opening from './Opening/Opening'
import Content from './Content/Content'
import Closing from './Closing/Closing'

const LandingRules = () => {
  const classes = useStyles()
  const location = useLocation()

  const pageName = location.pathname.split('/')[1]

  const [ objectTypeContent, setObjectTypeContent ] = useState(null)
  const [ listTypeContent, setListTypeContent ] = useState(null)

  useEffect(() => {
    const loadData = async () => {

      // GET RULES API
      let objectTypeEndPoint, listTypeEndPoint
      if(pageName === 'privacy-policy') {
        objectTypeEndPoint = apiEndPointList.privacyPolicyObject
        listTypeEndPoint = apiEndPointList.privacyPolicyList
      }
      else if(pageName === 'terms-of-service') {
        objectTypeEndPoint = apiEndPointList.termsOfServiceObject
        listTypeEndPoint = apiEndPointList.termsOfServiceList
      } 
      else if(pageName === 'support-regulation') {
        objectTypeEndPoint = apiEndPointList.supportRegulationObject
        listTypeEndPoint = apiEndPointList.supportRegulationList
      } 
  
      // SET OBJECT CONTENT
      const objectTypeData = await getLandingRulesAPI(objectTypeEndPoint)
      if(objectTypeData.status === 200){
        const data = objectTypeData.data.data
        setObjectTypeContent(data)
      } else {
        if(objectTypeData.status === 'No Server Response') {
          console.log('Connection Time Out')
        } else {console.log(objectTypeData.error.response)}
      }
  
      // SET LIST CONTENT
      const listTypeData = await getLandingRulesAPI(listTypeEndPoint)
      if(listTypeData.status === 200){
        const data = listTypeData.data.data
        setListTypeContent(data)
      } else {
        if(listTypeData.status === 'No Server Response') {
          console.log('Connection Time Out')
        } else {console.log(listTypeData.error.response)}
      }
    }

    loadData()
  }, [pageName])

  return (
    (objectTypeContent && listTypeContent) && 
    <Box className={classes.mainContainer}>

      {/* OPENING SECTION */}
      <Opening objectTypeContent ={objectTypeContent}  />

      {/* CONTENT SECTION */}
      <Content listTypeContent={listTypeContent} />

      {/* CLOSING SECTION */}
      <Closing pageName={pageName} objectTypeContent={objectTypeContent} />
      
    </Box>
  )
}

export default LandingRules