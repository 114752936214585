// ASSETS
import LandingSmartBuilder from 'assets/images/pictures/landing-smart-builder.svg'
import LandingAsetCollectData from 'assets/images/pictures/landing-aset-collect-data.svg'
import LandingAsetDeliver from 'assets/images/pictures/landing-aset-deliver.svg'

const dataFeatures = [
  {
    title: 'Build smarter forms',
    description: 'Use our simple drag-and-drop Form Builder to customize your mobile forms. Or select one of our service packages and let us do all the lifting for you.',
    maxWidthContent: 476,
    srcImage: LandingSmartBuilder,
    positionContent: 'left',
    linkLearnMore: '#'
  },
  {
    title: 'Collect data anywhere',
    description: 'Your teams can complete and submit mobile forms anywhere – even offline. Unlike paper, your forms can now validate answers, collect rich media, and reference live data resources.',
    maxWidthContent: 517,
    srcImage: LandingAsetCollectData,
    positionContent: 'right',
    linkLearnMore: '#'
  },
  {
    title: 'Deliver to the right places',
    description: 'Information is delivered exactly where you need it. Flag issues for immediate attention. Send responses directly to your data systems to eliminate data re-entry.',
    maxWidthContent: 494,
    srcImage: LandingAsetDeliver,
    positionContent: 'left',
    linkLearnMore: '#'
  }
]

export default dataFeatures