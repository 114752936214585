// MUIS
import { 
  createTheme, 
  // responsiveFontSizes, 
} from '@mui/material/styles'

let customTheme = createTheme({
  palette: {
    primary: {
      main: '#000000', // BLACK
    },
    secondary: {
      main: '#FFFFFF', // WHITE
    },
    text: {
      primary: '#000000', // BLACK
      secondary: '#FFFFFF', // WHITE
    },
  },
  typography: {
    fontFamily: [
      'Space Mono', 'Inter', 'Roboto', 'sans-serif',
    ].join(','),
  },
})

// customTheme = responsiveFontSizes(customTheme) 

export default customTheme