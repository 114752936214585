// MUI STYLES
import { makeStyles } from '@mui/styles'

// CONSTAN
import { colorsLanding } from 'constants/colors'

const useStyles = makeStyles((theme) => ({
  heroContainer : {
    padding : '120px 339px',
    background : colorsLanding.cyan,
    borderBottom : '3px solid black'
  },
  heroTitle : {
    fontSize : 56,
    fontWeight : 700,
    lineHeight : '84px',
    marginBottom : 40,
  },
  heroValue : {
    fontFamily  : [ 
      'Inter', 'Roboto', 'sans-serif',
    ].join(','),
    fontSize : 16,
    fontWeight : 400,
  },
  contentText : {
    fontFamily  : [ 
      'Inter', 'Roboto', 'sans-serif',
    ].join(','),
    fontSize : 16,
    fontWeight : 400,
  },
  contentContainer : {
    padding : '120px 323px'
  },
}))

export default useStyles
