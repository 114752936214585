import React from 'react'

// MUIS
import { Box, Typography } from '@mui/material'

// STYLES
import useStyles from './closingUseStyles'

const Closing = (props) => {
  const classes = useStyles()
  const {objectTypeContent,pageName} = props

  const contactUS = () => {

    // PRIVACY POLICY 
    if(pageName === 'privacy-policy') {
      return (
        <Box className={classes.closingContainer}>
          {/* CONTACT US */}
          <Typography className={classes.contactUsText}>
            {objectTypeContent.closing.slice(0,104).replace('lacak.io', 'worx.id')}
          </Typography>
  
          <Typography className={classes.accordionDesc}>
            Worx.id <br/>
            PT. Lacak Cipta Aktual <br />
            50/F, Menara BCA Grand Indonesia, Jl. MH. Thamrin No. 1, Jakarta Pusat, 10310
          </Typography>
        </Box>
      )
    } 

    // TERM OF SERVICE
    else if (pageName === 'terms-of-service') {
      return (
        <Box className={classes.closingContainer}>
          {/* CONTACT US */}
          <Typography className={classes.contactUsText}>
            {objectTypeContent.closing.slice(0,73).replace('lacak.io', 'worx.id')}
          </Typography>

          <Typography className={classes.accordionDesc}>
            Worx.id <br/>
            PT. Lacak Cipta Aktual <br />
            50/F, Menara BCA Grand Indonesia, Jl. MH. Thamrin No. 1, Jakarta Pusat, 10310
          </Typography>
        </Box>
      )
    }

    // SUPPORT REGULATION
    else {
      return <Box className={classes.closingContainer}></Box>
    }
  }

  return (
    <>
      {contactUS()}
    </>
  )
}

export default Closing