// COLORS
import { colorsLanding } from 'constants/colors'

// MUIS STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    padding: 0
  },
  wrapper: {
    padding: 0
  },
  imageWrap: {
    backgroundColor: colorsLanding.portage,
    position: 'relative'
  },
  contentWrap: {
    backgroundColor: colorsLanding.casablanca,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  image: {
    width:'100%',
    height: '100%'
  },
  content: {
    padding: 0,
    maxWidth: 600,
    width: '100%',
  },
  contentTitle: {
    fontSize: 40,
    fontWeight: 700,
    lineHeight: 1.3,
    marginBottom: 24,
    padding: '0'
  },
  contentDescription: {
    marginBottom: 65,
    fontFamily: ['Inter', 'Roboto', 'sans-serif'].join(','),
    maxWidth: 527,
    width: '100%',
    fontWeight: 400,
    letterSpacing: '0.0015em'
  },
  listCount: {
    display: 'flex',
    flexFlow: 'row nowrap'
  },
  listCountItem: {
    flex: '0 1 auto',
    display: 'block',
    borderRight: `2px solid ${theme.palette.primary.main}`,
    paddingRight: 30,
    paddingLeft: 30,
    paddingTop: 0,
    paddingBottom: 0,
    '&:last-child': {
      borderRight: 'none',
      paddingRight: 0
    },
    '&:first-child': {
      paddingLeft: 0
    }
  },
  listCountTotal: {
    fontSize: 50,
    lineHeight: 1.1,
    fontWeight: 700,
    marginBottom: 22,
    fontFamily: ['Inter', 'Roboto', 'sans-serif'].join(','),
    width: 160,
  },
  listCountDescription: {
    fontSize: 18,
    fontWeight: 400,
    lineHeight: 1.2,
    fontFamily: ['Inter', 'Roboto', 'sans-serif'].join(','),
  }
}))

export default useStyles