import React, { useState } from 'react'

// MUIS
import { Box } from '@mui/material'

// COMPONENT
import LandingTextField from 'components/LandingTextField/LandingTextField'

// STYLES
import useStyles from './formUseStyles'

const Form = () => {
  const classes = useStyles()

  const [inputs, setInputs] = useState({})

  const handleChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    setInputs(values => ({...values, [name]: value}))
  }

  const listForm = [
    {
      'type' : 'text',
      'name' : 'firsName',
      'inputLabel' : 'First Name*',
      'value' : inputs.firsName || ''
    },
    {
      'type' : 'text',
      'name' : 'lastName',
      'inputLabel' : 'Last Name*',
      'value' : inputs.lastName || ''
    },
    {
      'type' : 'email',
      'name' : 'email',
      'inputLabel' : 'Email*',
      'value' : inputs.email || ''
    },
    {
      'type' : 'number',
      'name' : 'phoneNumber',
      'inputLabel' : 'Phone Number*',
      'value' : inputs.phoneNumber || ''
    },
    {
      'type' : 'text',
      'name' : 'companyName',
      'inputLabel' : 'Company Name*',
      'value' : inputs.companyName || ''
    },
    {
      'type' : 'text',
      'name' : 'companySize',
      'inputLabel' : 'Company Size*',
      'value' : inputs.companySize || ''
    },
    {
      'type' : 'text',
      'name' : 'country',
      'inputLabel' : 'Country*',
      'value' : inputs.country || ''
    },
  ]

  return (
    <Box>
      {/* FORM */}
      {listForm.map((item, index)=> (
        <Box key={index} className={classes.textFieldContainer}>
          <LandingTextField
            fullWidth
            type = {item.type}
            name = {item.name}
            className={classes.textField}
            inputLabel={item.inputLabel}
            value={item.value}
            onChange={handleChange}/>
        </Box>
      ))}
    </Box>
  )
}

export default Form