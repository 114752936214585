// REACT ROUTER
import { useNavigate } from 'react-router-dom'

// STYLES 
import useStyles from './landingFooterUseStyles'

// MUIS
import { Box, Grid, Typography, Link } from '@mui/material'

// MUI ICONS
import FacebookIcon from '@mui/icons-material/Facebook'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import InstagramIcon from '@mui/icons-material/Instagram'

// ASSETS
import LogoWorxBlack from 'assets/images/logos/company-worx-white.png'

const Footer = () => {
  const classes = useStyles()
  const navigate = useNavigate()

  const listMenu =[
    {
      'title' : 'Privacy Policy',
      'path' : '/privacy-policy'
    },
    {
      'title' : 'Terms of Service',
      'path' : '/terms-of-service'
    },
    {
      'title' : 'Support Regulation',
      'path' : '/support-regulation'
    },
  ]

  const handleIconClick = (inputUrl) => {
    const newWindow = window.open(inputUrl, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return (
    <Box className={classes.mainContainer}>

      {/* TOP CONTENT */}
      <Box className={classes.topContentContainer}>
        {/* COMPANY LOGO */}
        <Box component='img'
          src={LogoWorxBlack}
          alt='Worx Logo'
          onClick={() => navigate('/')}
          className={classes.logoCompany}/>

        {/* MENU */}
        <Box className={classes.gridContainer}>
          <Grid className={classes.gridFlex} container>
            {listMenu.map((item,index) =>(
              <Grid key={index} item xs={3}>
                <Link  href={item.path} className={classes.link}>
                  <Typography className={classes.textLink} align='right'> {item.title} </Typography>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      {/* BOTTOM CONTENT */}
      <Box className={classes.bottomContainer}>

        {/* TYPOGRHAPY */}
        <Typography className={classes.textBottom}>
          &copy; Worx.id. 2022. All rights reserved
        </Typography>

        {/* ICON */}
        <Box>
          <FacebookIcon 
            onClick={()=>handleIconClick('https://facebook.com/')} 
            className={classes.icon}/>

          <LinkedInIcon 
            onClick={()=>handleIconClick('https://linkedin.com/')}  
            className={classes.icon}/>

          <InstagramIcon 
            onClick={()=>handleIconClick('https://instagram.com/')} 
            className={classes.icon}/>
        </Box>

      </Box>
    </Box>
  )
}

export default Footer