// MUI STYLES
import { makeStyles } from '@mui/styles'

// COLORS
import { colorsLanding } from 'constants/colors'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  contentLeft: {
    padding: '120px 80px 120px 140px',
    backgroundColor: '#FFFFFF',
    borderRight: `3px solid ${theme.palette.primary.main}`,
    borderBottom: `3px solid ${theme.palette.primary.main}`
  },
  contentRight: {
    padding: '120px 140px 120px 80px',
    backgroundColor: colorsLanding.starship,
    borderBottom: `3px solid ${theme.palette.primary.main}`
  },
  contentTitle: {
    fontWeight: 700,
    fontSize: 40,
    lineHeight: 1.45,
    marginBottom: 24
  },
  contentDescription: {
    fontFamily: ['Inter', 'Roboto', 'sans-serif'].join(','),
    fontSize: 16,
    lineHeight: 1.45,
    marginBottom: 40
  },
  listPlans: {
    padding: 0,
    margin: 0,
  },
  listPlansItem: {
    padding: '0 0 24px 0',
    display: 'flex',
    alignItems: 'start',
    '&:last-child': {
      padding: 0
    }
  },
  iconWrap: {
    paddingTop: '1px',
    paddingRight: '16px'
  },
  iconChecked: {
    fontSize: '20px',
    color: colorsLanding.breakerBay
  },
  listTitle: {
    fontFamily: ['Inter', 'Roboto', 'sans-serif'].join(','),
    fontSize: '16px',
    lineHeight: 1.5,
    fontWeight: 400
  },
  listDescription: {
    fontFamily: ['Inter', 'Roboto', 'sans-serif'].join(','),
    fontSize: '16px',
    lineHeight: 1.5,
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.5)'
  },
  buttonWrap: {
    marginTop: '80px'
  },
  buttonCustom: {
    width: '100%',
    fontSize: 18
  }
}))

export default useStyles