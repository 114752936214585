// MUI STYLES
import { makeStyles } from '@mui/styles'

// COLORS
import { colorsLanding } from 'constants/colors'

const useStyles = makeStyles((theme) => ({
  mainContainer : {
    height : '100%',
    width : '100%',
    display : 'flex'
  },
  rightContent : {
    background : colorsLanding.breakerBay,
    height : '100%',
    width : '100%',
  },
  leftContent : {
    padding : '90px 140px',
    borderRight : 'solid 3px black',
    display : 'flex',
    justifyContent : 'center'
  },
  leftContainer : {
    maxWidth :754
  },
  logoCompany : {
    width : 110,
    height : 32,
    marginBottom : 94,
    cursor : 'pointer'
  },
  titleContent : {
    fontSize : 56,
    fontWeight : 700,
    lineHeight : '84px',
    marginBottom : 40,
  },
  text1 : {
    fontFamily: [ 
      'Inter', 'Roboto', 'sans-serif',
    ].join(','),
    maxWidth : 597,
    lineHeight : '24px',
    marginBottom : 56,
  },
  submitButtonContainer : {
    display : 'flex',
    flexDirection : 'column',
    alignItems : 'center',
    marginBottom : 30,
  },
  text2 : {
    maxWidth : 597,
    fontSize : 16,
    fontWeight : 400,
    marginTop : 24,
    fontFamily: [ 
      'Inter', 'Roboto', 'sans-serif',
    ].join(','),
  },
  submitBtn : {
    marginTop : 56,
    width : '100%',
  }
}))

export default useStyles
