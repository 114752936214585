// REACT ROUTER
import { useNavigate } from 'react-router-dom'

// STYLES
import useStyles from './tryItFreeUseStyles'

// MUIS
import { Box, Checkbox, Grid, Typography, FormGroup, FormControlLabel  } from '@mui/material'

// LANDING COMPONENT
import LandingButton from 'components/LandingButton/LandingButton'
import LandingLink from 'components/LandingLink/LandingLink'

// COMPONENT
import Form from './Form/Form'

// ASSETS
import LogoWorxBlack from 'assets/images/logos/company-worx-black.png'

const TryItFree = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  
  return (
    <Box className={classes.mainContainer}>
      <Grid container>

        {/* LEFT CONTENT */}
        <Grid item xs={9} className={classes.leftContent}>
          <Box className={classes.leftContainer}>

            {/* COMPANY LOGO & LOG IN */}
            <Box className={classes.logoContainer}>
              {/* LOGO */}
              <Box component='img'
                src={LogoWorxBlack}
                alt='Worx Logo'
                onClick={() => navigate('/')}
                className={classes.logoCompany}/>

              {/* LINK */}
              <Box>
                <LandingLink className={classes.textLink}>
                  Log In
                </LandingLink>
              </Box>
            </Box>

            {/* TITLE CONTENT */}
            <Typography className={classes.titleContent}>
              Start Your 14-Day Trial
            </Typography>

            {/* FORM */}
            <Form/>

            {/* CHECKBOX */}
            <FormGroup className={classes.checBoxContainer}>
              <FormControlLabel 
                className={classes.checkBoxText} 
                control={<Checkbox className={classes.checkBox}/>} 
                label='I have read and agree to the Terms & Conditions and Privacy Policy.' />
            </FormGroup>

            <FormGroup className={classes.checBoxContainer}>
              <FormControlLabel 
                className={classes.checkBoxText} 
                control={<Checkbox className={classes.checkBox}/>} 
                label='I would like to receive marketing communications regarding Worx.id and GoCanvas products, services, and events. I can unsubscribe at a later time.' />
            </FormGroup>

            {/* SUBMIT BUTTON */}
            <Box className={classes.submitButtonContainer}>
              <LandingButton 
                color='primary' 
                variant='contained'
                className={classes.submitBtn}
              >
                Submit
              </LandingButton>
            </Box>
            
          </Box>
        </Grid>

        {/* RIGHT CONTENT */}
        <Grid item xs={3} className={classes.rightContent}>
        </Grid>

      </Grid>
    </Box>
  )
}

export default TryItFree