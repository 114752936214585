// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  contentContainer : {
    padding : '0px 323px',
    width : '100%',
    height : '100%'
  },
  accordionDivider : {
    border : 'none',
    borderBottom : '3px solid black'
  },
  accordion : {
    boxShadow : 'none',
    borderBottom : 'solid 3px black',
    borderRadius : '0px !important',
    margin : '0px !important',
  },
  accordionSummary : {
    padding : '24px 0px',
    paddingRight : 10,
    '& .MuiAccordionSummary-content' : {
      margin : 0
    }
  },
  accordionTitle : {
    fontSize : 16,
    fontWeight : 600,
    fontFamily : [ 
      'Inter', 'Roboto', 'sans-serif',
    ].join(','),
  },
  accordionDetail  :{
    '&.MuiAccordionDetails-root' : {
      padding : '0px 0px 24px 0px !important'
    }
  },
  accordionDesc : {
    fontFamily  : [ 
      'Inter', 'Roboto', 'sans-serif',
    ].join(','),
    fontSize : 16,
    fontWeight : 400,
    whiteSpace : 'pre-wrap'
  },
  expandIcon : {
    color : 'black'
  }
}))

export default useStyles
