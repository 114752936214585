import { useState } from 'react'

// COMPONENTS
import LandingButton from 'components/LandingButton/LandingButton'
import LandingLink from 'components/LandingLink/LandingLink'
import LandingTextField from 'components/LandingTextField/LandingTextField'

// MUIS
import Box from '@mui/material/Box'

// MUI ICONS
import IconSend from '@mui/icons-material/Send'

// STYLES
import useStyles from './landingDummyUseStyles'

const LandingDummy = () => {
  const classes = useStyles()

  const [ input, setInput ] = useState('')

  console.log('input:', input)

  return (
    <Box>
      <LandingButton
        className={classes.item}
        color='primary'
        variant='contained'
      >
        Contained Primary Button
      </LandingButton>

      <br/>

      <LandingButton
        className={classes.item}
        color='secondary'
        variant='contained'
      >
        Contained Secondary Button
      </LandingButton>

      <br/>

      <LandingButton
        className={classes.item}
        color='primary'
        variant='text'
        endIcon={<IconSend/>}
      >
        Text Primary Button
      </LandingButton>

      <br/>

      <LandingLink className={classes.item}>
        Link
      </LandingLink>

      <br/>

      <LandingTextField
        className={classes.item}
        inputLabel='Text Field'
        value={input}
        onChange={(event) => setInput(event.target.value)}
      />
    </Box>
  )
}

export default LandingDummy