// PAGES
import LandingDummy from 'pages/LandingDummy/LandingDummy'
import LandingFeatures from 'pages/LandingFeatures/LandingFeatures'
import LandingHome from 'pages/LandingHome/LandingHome'
import LandingPricing from 'pages/LandingPricing/LandingPricing'
import LandingRules from 'pages/LandingRules/LandingRules'
import LandingScheduleADemo from 'pages/LandingScheduleADemo/LandingScheduleADemo'
import LandingTryItFree from 'pages/LandingTryItFree/LandingTryItFree'

const routes = [
  // LANDING
  {
    path: '/',
    element: <LandingHome/>,
    routeType: 'landing',
    isWithAppBar: true,
    isWithFooter: true,
  },
  {
    path: '/features',
    element: <LandingFeatures/>,
    routeType: 'landing',
    isWithAppBar: true,
    isWithFooter: true,
  },
  {
    path: '/schedule-a-demo',
    element: <LandingScheduleADemo/>,
    routeType: 'landing',
    isWithAppBar: false,
    isWithFooter: true,
  },
  {
    path: '/try-it-free',
    element: <LandingTryItFree/>,
    routeType: 'landing',
    isWithAppBar: false,
    isWithFooter: true,
  },
  {
    path: '/pricing',
    element: <LandingPricing/>,
    routeType: 'landing',
    isWithAppBar: true,
    isWithFooter: true,
  },
  {
    path: '/privacy-policy',
    element: <LandingRules/>,
    routeType: 'landing',
    isWithAppBar: true,
    isWithFooter: true,
  },
  {
    path: '/terms-of-service',
    element: <LandingRules/>,
    routeType: 'landing',
    isWithAppBar: true,
    isWithFooter: true,
  },
  {
    path: '/support-regulation',
    element: <LandingRules/>,
    routeType: 'landing',
    isWithAppBar: true,
    isWithFooter: true,
  },
  {
    path: '/dummy',
    element: <LandingDummy/>,
    routeType: 'landing',
    isWithAppBar: true,
    isWithFooter: true,
  },
]

export default routes