// COLORS
import { colorsLanding } from 'constants/colors'

// MUIS STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: colorsLanding.starship,
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    padding: '194px 20px 243px 20px',
    position: 'relative'
  },
  subtitle: {
    fontFamily: ['Inter', 'Roboto', 'sans-serif'].join(','),
    textAlign: 'center',
    marginBottom: 40,
  },
  title: {
    fontSize: 56,
    fontWeight: 700,
    textAlign: 'center',
    maxWidth: 822,
    width: '100%',
    margin: '0 auto 40px auto',
    lineHeight: 1.5
  },
  description: {
    maxWidth: 612,
    width: '100%',
    lineHeight: 1.5,
    textAlign: 'center',
    margin: '0 auto 56px auto',
    fontFamily: ['Inter', 'Roboto', 'sans-serif'].join(',')
  },
  buttonWrap: {
    textAlign: 'center'
  },
  buttonCustom: {
    fontSize: 18,
    padding: '10.5px 88.5px'
  },
  iconWifi: {
    position: 'absolute',
    width: 217,
    left: '-40px',
    top: 241,
    zIndex: 9,
  },
  iconMarker: {
    position: 'absolute',
    width: 179,
    right: '-14px',
    top: 25,
    zIndex: 9,
  },
  iconUser: {
    position: 'absolute',
    width: 217,
    left: 171,
    bottom: '-111px',
    zIndex: 9,
  },
  iconSlice: {
    position: 'absolute',
    width: 206,
    right: 155.55,
    bottom: 115.4,
    zIndex: 9,
  }
}))

export default useStyles