// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 0,
    textTransform: 'unset',
    fontWeight: 700,
  },
}))

export default useStyles