import React from 'react'

// MUIS
import { Box, Grid, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'

// MUI ICON
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'

//STYLES
import useStyles from './gridFeaturesUseStyles'

// CONSTAN
import { colorsLanding } from 'constants/colors'

const GridFeatures = (props) => {
  const classes = useStyles()
  const {listData} = props

  return ( 
    <Box className={classes.mainContainer}>
      {listData?.map((item,index) => (
        <Grid key={index} container 
          sx={{flexDirection : item.leftContent ? 'row' : 'row-reverse'}}>

          {/* CONTENT */}
          <Grid item lg={6}>
            <Box 
              className={item.leftContent ?  classes.contentLeft : classes.contentRight}
              sx={{color : item.isPink ? colorsLanding.lavenderMagenta : colorsLanding.portage}}
            >

              {/* TITLE */}
              <Typography className={classes.title}>
                {item.title}
              </Typography>

              {/* VALUE */}
              {item.desc.map((val,idx)=> (
                <List key={idx}>
                  <ListItem alignItems='flex-start' disablePadding>
                    <ListItemIcon className={classes.valueListIcon}>
                      <FiberManualRecordIcon className={classes.valueIcon} />
                    </ListItemIcon>
                    <ListItemText className={classes.value} primary={val.value} />
                  </ListItem>
                </List>
              ))}
            </Box>
          </Grid>

          {/* IMAGE */}
          <Grid item lg={6} 
            className={item.imagecenter ? classes.imageCenter : classes.imageEnd}
            sx={{background : item.isPink ? colorsLanding.lavenderMagenta : colorsLanding.portage,
              justifyContent : item.flexEnd ? 'flex-end' : 'center',
              overflow : item.flexEnd ? 'hidden' : 'unset'}}>
            <Box
              component='img'
              src={item.image}
              className={classes.image}
              sx={{maxWidth: item.maxWidth ? item.maxWidth : 'auto',
                marginRight : item.flexEnd ? '-2.3%' : 0}}
            />
          </Grid>
        </Grid>
      ))}
    </Box>
  )
}

export default GridFeatures