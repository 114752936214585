// STYLES
import useStyles from './plansUseStyles'

// MUIS
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'

// COMPONENTS
import ListChecked from './ListChecked'

// DATA
import { dataContentLeft, dataContentRight } from './dataPlans'

// ROUTER
import { useNavigate } from 'react-router-dom'

const Comparison = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  
  return (
    <Container maxWidth={false} className={classes.root}>
      <Grid container spacing={0}>
        {/* CONTENT LEFT */}
        <Grid item xs={12} lg={6} className={classes.contentLeft}>
          {/* LIST */}
          <ListChecked
            contentTitle='Professional Plan'
            contentDescription='A powerful off-the-shelf solution for most professional organizations.'
            listData={dataContentLeft}
            buttonText='Free 30-Day Trial'
            buttonColor='secondary'
            onClickButton={() => navigate('#')}
          />
        </Grid>

        {/* CONTENT RIGHT */}
        <Grid item xs={12} lg={6} className={classes.contentRight}>
          {/* LIST */}
          <ListChecked
            contentTitle='Enterprise Plan'
            contentDescription='Advanced data and API capabilities for total control over your systems integrations.'
            listData={dataContentRight}
            buttonText='Contact Us'
            buttonColor='primary'
            onClickButton={() => navigate('#')}
          />
        </Grid>
      </Grid>
    </Container>
  )
}

export default Comparison