import React from 'react'

// MUIS
import { Box, Divider, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material'

// STYLES
import useStyles from './contentUseStyles'

// MUI ICON
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const Content = (props)=> {
  const classes = useStyles()
  const {listTypeContent} = props

  return (
    <Box className={classes.contentContainer}>

      {/* DIVIDER */}
      <Divider className={classes.accordionDivider}/>

      {/* ACCORDION */}
      {listTypeContent.map((item,index)=>(
        <Accordion key={index} className={classes.accordion}>
          <AccordionSummary className={classes.accordionSummary}
            expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
          >
            <Typography className={classes.accordionTitle}>{item.title}</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetail}>
            <Typography className={classes.accordionDesc}>
              {item.description.replace(/Lacak.io/g, 'Work.id').replace(/lacak.io/g, 'worx.id')} 
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
      
    </Box>
  )
}

export default Content