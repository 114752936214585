import React from 'react'

// MUIS
import MuiGlobalStyles from '@mui/material/GlobalStyles'
import { grey } from '@mui/material/colors'

const GlobalStyles = () => {
  // const zoomValue = 0.85

  return (
    <MuiGlobalStyles
      styles={(theme) => ({
        // ALL ELEMENTS
        '*, *::before, *::after': {
          boxSizing: 'border-box',
          fontFamily: [
            'Space Mono', 'Inter', 'Roboto', 'sans-serif',
          ].join(','),
        },

        // SCROLLBAR
        '&::-webkit-scrollbar': {
          width: 5,
          height: 5,
          backgroundColor: grey[200],
          borderRadius: 5,
        },
        '&::-webkit-scrollbar-thumb': {
          width: 5,
          height: 5,
          backgroundColor: grey[400],
          borderRadius: 5,
        },

        // ZOOM
        '@media only screen and (max-height: 800px) and (min-width: 1200px)': {
          'body': {
            // zoom: zoomValue,
          },
          '.zoom': {
            // zoom: zoomValue,
          },
          '.no-zoom': {
            // zoom: 1 / zoomValue,
          },
        },
      })}
    />
  )
}

export default GlobalStyles