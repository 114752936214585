// ASSETS
import logoValid from 'assets/images/logos/company-valid.png'
import logoMIT from 'assets/images/logos/company-mit.png'
import logoTransporta from 'assets/images/logos/company-transporta.png'
import logoLacak from 'assets/images/logos/company-lacak.png'


const dataCompany = [
  {
    alt: 'Company Valid',
    src: logoValid,
    width: '147px'
  },
  {
    alt: 'Company MIT',
    src: logoMIT,
    width: '81px'
  },
  {
    alt: 'Company Transporta',
    src: logoTransporta,
    width: '213.26px'
  },
  {
    alt: 'Company Lacak',
    src: logoLacak,
    width: '170.45px'
  }
]

export default dataCompany