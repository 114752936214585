// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  mainContainer : {
    padding : '120px 388px',
    paddingBottom : 137,
    borderBottom : '3px solid black'
  },
  title : {
    fontWeight : 700,
    fontSize : 40,
    lineHeight : '59px',
    marginBottom : 24,
  },
  value : {
    fontFamily  : [ 
      'Inter', 'Roboto', 'sans-serif',
    ].join(','),
    fontSize : 16,
    fontWeight : 400,
  },
}))

export default useStyles
