// REACT ROUTER
import { useNavigate } from 'react-router-dom'

// STYLES
import useStyles from './landingScheduleADemoUseStyles'

// MUIS
import { Box,Grid, Typography } from '@mui/material'

// ASSETS
import LogoWorxBlack from 'assets/images/logos/company-worx-black.png'

// COMPONENT
import Form from './Form/Form'

// LANDING COMPONENT
import LandingButton from 'components/LandingButton/LandingButton'

const ScheduleADemo = () => {
  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <Box className={classes.mainContainer}>
      <Grid container>

        {/* LEFT CONTENT */}
        <Grid item xs={9} className={classes.leftContent}>
          <Box className={classes.leftContainer}>
            {/* COMPANY LOGO */}
            <Box component='img'
              src={LogoWorxBlack}
              onClick={() => navigate('/')}
              alt='Work Logo'
              className={classes.logoCompany}/>

            {/* TITLE CONTENT */}
            <Typography className={classes.titleContent}>
              Schedule A Personalized Demo
            </Typography>
            <Typography className={classes.text1} variant='subtitle1'>
              Our team will walk you through the Device Magic app and answer any questions you may have!
            </Typography>

            {/* FORM */}
            <Form/>

            {/* TYPOGRAPHY & SUBMIT BUTTON */}
            <Box className={classes.submitButtonContainer}>
              {/* TYPOGRAPHY */}
              <Typography className={classes.text2} align='center'>
                By submitting this form, you confirm that you agree to the storing and processing of your personal data as described in our Terms of Service and Privacy Policy.
              </Typography>

              {/* SUBMIT BUTTON */}
              <LandingButton 
                color='primary' 
                variant='contained'
                className={classes.submitBtn}
              >
                Submit
              </LandingButton>
            </Box>
          </Box>
        </Grid>

        {/* RIGHT CONTENT */}
        <Grid item xs={3} className={classes.rightContent}>
        </Grid>
        
      </Grid>
    </Box>
  )
}

export default ScheduleADemo