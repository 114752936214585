import { Routes, Route } from 'react-router-dom'

// CONSTANTS
import customThemeLanding from 'constants/customThemeLanding'

// LAYOUTS
import Landing from 'layouts/Landing/Landing'

// MUIS
import { ThemeProvider } from '@mui/material/styles'

// ROUTES
import routes from 'routes/routes'

const App = () => {
  const getRouteComponent = (inputItem) => {
    return (
      <ThemeProvider theme={customThemeLanding}>
        <Landing
          isWithAppBar={inputItem.isWithAppBar}
          isWithFooter={inputItem.isWithFooter}
        >
          {inputItem.element}
        </Landing>
      </ThemeProvider>
    )
  }
  
  return (
    <Routes>
      {routes.map((item, index) => (
        <Route 
          key={index}
          path={item.path} 
          element={getRouteComponent(item)}
        />
      ))}
    </Routes>
  )
}

export default App
