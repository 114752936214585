// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    fontWeight: 700,
    cursor: 'pointer',
  },
}))

export default useStyles