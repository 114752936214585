// https://chir.ag/projects/name-that-color/

export const colorsLanding = {
  breakerBay: '#4D9E94', // BLUE - GREEN
  burntSienna: '#EF785B', // CREAM
  casablanca: '#F8CA45', // YELLOW - ORANGE
  cyan: '#39DBFF', // AQUA
  elSalva: '#8D312F', // CHOCOLATE
  lavenderMagenta: '#F098E4', // PINK
  starship: '#F2F15D', // YELLOW
  portage: '#94A8E8', // VIOLET
}

export const colorsDashboard = {}