import React from 'react'

// SECTIONS
import Hero from './Hero'
import Companies from './Companies'
import ReasonToUse from './ReasonToUse'
import Features from './Features'
import Benefits from './Benefits'
import StartNow from 'components/LandingStartNow'

const Home = () => {
  return (
    <>
      {/* HERO SECTION */}
      <Hero />

      {/* COMPANIES SECTION */}
      <Companies />

      {/* REASON TO USE SECTION */}
      <ReasonToUse />

      {/* FEATURES SECTION */}
      <Features />

      {/* BENEFITS SECTION */}
      <Benefits />

      {/* START NOW */}
      <StartNow />
    </>
  )
}

export default Home