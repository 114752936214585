// MUIS STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    padding: '40px 140px 43.78px 140px'
  },
  listCompanies: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0
  },
  listCompaniesItem: {
    flex: '0 1 auto',
    padding: 0,
    marginRight: 82,
    '&:last-child': {
      marginRight: 0
    }
  }
}))

export default useStyles