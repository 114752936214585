import React from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

// MUIS
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// STYLES
import useStyles from '../featuresUseStyles'
import LandingButtonContained from 'components/LandingButton/LandingButton'

// ICONS
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'

const Content = (props) => {
  const {
    maxWidthContent,
    maxWidthImage,
    positionContent,
    title,
    description,
    srcImage,
    linkLeanMore
  } = props

  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <Grid
      container
      spacing={2}
      className={classes.itemWrapper}
      sx={{
        flexDirection: positionContent === 'right' ? 'row-reverse' : 'row'
      }}

    >
      <Grid item lg={6}>
        {/* CONTENT */}
        <Box
          sx={{
            maxWidth: maxWidthContent ? maxWidthContent : 'auto',
            paddingRight: positionContent === 'left' ? '40px' : 0,
            paddingLeft: positionContent === 'right' ? '40px' : 0
          }}
        >
          {/* TITLE */}
          <Typography variant='h3' className={classes.contentTitle}>
            {title}
          </Typography>

          {/* DESCRIPTION */}
          <Typography component='p' variant='body1' className={classes.contentDescription}>
            {description}
          </Typography>

          <LandingButtonContained
            className={classes.contentButton}
            color='primary'
            variant='text'
            endIcon={<ArrowRightAltIcon/>}
            onClick={() => navigate(linkLeanMore)}
          >
            Learn More
          </LandingButtonContained>
        </Box>
      </Grid>
      
      <Grid item lg={6}>
        <Box
          component='img'
          src={srcImage}
          className={classes.image}
          sx={{
            maxWidth: maxWidthImage ? maxWidthImage : 'auto'
          }}
        />
      </Grid>
    </Grid>
  )
}

Content.defaultProps = {
  positionContent: 'left',
  linkLeanMore: '#'
}

Content.propTypes = {
  maxWidthContent: PropTypes.number || PropTypes.string,
  maxWidthImage: PropTypes.number || PropTypes.string,
  positionContent: PropTypes.oneOf(['left', 'right']),
  title: PropTypes.string,
  description: PropTypes.string,
  srcImage: PropTypes.string,
  linkLeanMore: PropTypes.string
}

export default Content