import React from 'react'

// STYLES
import useStyles from './benefitsUseStyles'

// MUIS
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

// COMPONENTS
import CardBenefits from './CardBenefits'

// DATA
import dataBenefits from './dataBenefits'

const Benefits = () => {
  const classes = useStyles()

  return (
    <Container maxWidth={false} className={classes.root}>
      {/* TITLE SECTION */}
      <Box className={classes.titleSectionWrap}>
        {/* TITLE */}
        <Typography variant='h3' className={classes.titleSection}>
          Work smarter. Not harder.
        </Typography>

        {/* DESCRIPTION */}
        <Typography variant='body1' className={classes.descriptionSection}>
          Use the #1 platform engineered to excel on your most important industry tasks. Device Magic helps businesses run better.
        </Typography>
      </Box>

      {/* LIST BENEFITS */}
      <Grid container spacing={4}>
        {/* ITEM */}
        {dataBenefits && dataBenefits.map((item, index) => (
          <Grid item md={6} lg={3} key={index}>
            {/* CARD BENEFITS */}
            <CardBenefits
              srcIcon={item.srcIcon}
              title={item.title}
              description={item.description}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

export default Benefits