import PropTypes from 'prop-types'

// COMPONENTS
import LandingAppBar from 'components/LandingAppBar/LandingAppBar'
import LandingFooter from 'components/LandingFooter/LandingFooter'

// MUIS
import Box from '@mui/material/Box'

// STYLES
import useStyles from './landingUseStyles'

const Main = (props) => {
  const { 
    isWithAppBar, 
    isWithFooter, 
    children,
  } = props

  const classes = useStyles()

  return (
    <Box className={classes.root}>
      {/* APPBAR */}
      {isWithAppBar && <LandingAppBar/>}

      {/* CHILDREN */}
      <Box
        sx={{
          marginTop: isWithAppBar ? '93.5px' : 0
        }}
      >
        {children}
      </Box>

      {/* FOOTER */}
      {isWithFooter && <LandingFooter/>}
    </Box>
  )
}

Main.defaultProps = {
  isWithAppBar: true, 
  isWithFooter: true, 
}

Main.propTypes = {
  isWithAppBar: PropTypes.bool.isRequired,
  isWithFooter: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
}

export default Main