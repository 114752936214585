// STYLES
import useStyles from './heroUseStyles'

// MUIS
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// DATA
import { dataIcons } from './dataHero'

const Hero = () => {
  const classes = useStyles()
  
  return (
    <Container className={classes.root} maxWidth={false}>
      {/* SUBTITLE */}
      <Typography variant='body1' className={classes.subtitle}>
        Pricing
      </Typography>

      {/* TITLE */}
      <Typography variant='h2' className={classes.title}>
        Pricing Option
      </Typography>

      {/* DESCRIPTION */}
      <Typography className={classes.description}>
        Give us a try FREE for 30 days or talk with one of our experts to customize the right plan for your team.
      </Typography>

      {/* ICON COINS */}
      {dataIcons && dataIcons.map((item, index) => (
        <Box
          key={index}
          component='img'
          src={item.src}
          className={classes.iconCoin}
          sx={item.style}
        />
      ))}
    </Container>
  )
}

export default Hero