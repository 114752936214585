// COLORS
import { colorsLanding } from 'constants/colors'

// MUIS STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: colorsLanding.casablanca,
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    padding: '187px 20px 217px 20px',
    position: 'relative',
  },
  subtitle: {
    fontFamily: ['Inter', 'Roboto', 'sans-serif'].join(','),
    textAlign: 'center',
    marginBottom: 40,
  },
  title: {
    fontSize: 56,
    fontWeight: 700,
    textAlign: 'center',
    maxWidth: 822,
    width: '100%',
    margin: '0 auto 40px auto',
    lineHeight: 1.5
  },
  description: {
    maxWidth: 612,
    width: '100%',
    lineHeight: 1.5,
    textAlign: 'center',
    margin: '0 auto',
    fontFamily: ['Inter', 'Roboto', 'sans-serif'].join(',')
  },
  iconCoin: {
    position: 'absolute'
  },
}))

export default useStyles