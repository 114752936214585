// APIS
import axiosLacak from 'apis/axiosLacak'

// API END POINT LIST 
// BASE: cmsd.lacak.io/items/
const apiEndPointList = {
  privacyPolicyList: 'privacy_policy_list',
  privacyPolicyObject: 'privacy_policy_object',
  supportRegulationList: 'support_regulation_list',
  supportRegulationObject: 'support_regulation_object',
  termsOfServiceList: 'terms_of_service_list',
  termsOfServiceObject: 'terms_of_service_object',
}

// GET LANDING RULES API
const getLandingRulesAPI = async (inputEndpoint) => {
  try {
    const response = await axiosLacak.get(
      `/items/${inputEndpoint}`,
      { 
        headers: {
          'Content-Type': 'application/json',
        }, 
      },
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export {getLandingRulesAPI, apiEndPointList}