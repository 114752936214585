// MUIS STYLES
import { makeStyles } from '@mui/styles'

// COLORS
import { colorsLanding } from 'constants/colors'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '120px 140px',
    backgroundColor: colorsLanding.casablanca,
    borderBottom: `3px solid ${theme.palette.primary.main}`,
  },
  titleSectionWrap: {
    marginBottom: 100
  },
  titleSection: {
    maxWidth: 673,
    width: '100%',
    fontSize: 40,
    fontWeight: 700,
    lineHeight: 1.45,
    margin: '0 auto 24px auto',
    textAlign: 'center'
  },
  descriptionSection: {
    maxWidth: 724,
    width: '100%',
    margin: '0 auto',
    textAlign: 'center',
    fontFamily: ['Inter', 'Roboto', 'sans-serif'].join(','),
    lineHeight: 1.5
  },
  cardBenefits: {
    backgroundColor: '#FFFFFF',
    boxShadow: '8px 8px 0px 0px rgb(0,0,0)',
    border: `3px solid ${theme.palette.primary.main}`,
    padding: '24px 20px',
    height: '100%'
  },
  cardBenefitsIcon: {
    height: 60,
    marginBottom: 8,
  },
  cardBenefitsTitle: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: 1.5,
    marginBottom: 8
  },
  cardBenefitsDescription: {
    fontFamily: ['Inter', 'Roboto', 'sans-serif'].join(','),
    lineHeight: 1.5,
    fontSize: 16
  }
}))

export default useStyles