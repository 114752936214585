// ASSETS IMAGE
import LandingEasyToDrag from 'assets/images/pictures/landing-easy-to-drag.svg'
import CompleteForm from 'assets/images/pictures/landing-complete-form.svg'
import IntellegentForm from 'assets/images/pictures/landing-intellegent-form.svg'
import DispatchForm from 'assets/images/pictures/landing-dispatch-form.png'
import AutomateEntire from 'assets/images/pictures/landing-automate-entire.png'
import MultipleDelivery from 'assets/images/pictures/landing-multiple-delivery.png'
import CompleteForm2 from 'assets/images/pictures/landing-complete-form2.svg'
import ControlForm from 'assets/images/pictures/landing-control-form.svg'
import GenerateCustom from 'assets/images/pictures/landing-generate-custom.svg'
import LandingGeomapping from 'assets/images/pictures/landing-geomapping.svg'

export const dataFeatures = [
  {
    'title' : 'Easy drag-and-drop form builder',
    'desc' : [
      {
        'value' : 'Recreate your checklists, inspections, audits, and more with our user-friendly form builder.'
      },
      {
        'value' : 'Reduce entry errors with conditional logic, input validation, required fields, and calculated answers.'
      },
      {
        'value' : 'See what forms will look like on mobile devices with the Form Builder Preview.'
      }
    ],
    'image' : LandingEasyToDrag,
    'leftContent' : true,
    'imagecenter' : true,
    'maxWidth' : 691,
  },
  {
    'title' : 'Complete forms while offline',
    'desc' : [
      {
        'value' : 'Your teams need the ability to complete and submit forms from any location - even where the internet is unavailable.'
      },
      {
        'value' : 'Unlike most alternatives, Device Magic lets users submit forms offline. As soon as an internet connection is detected, any completed forms will sync automatically.'
      },
    ],
    'image' : CompleteForm,
    'leftContent' : false,
    'imagecenter' : false,
    'maxWidth' : 672,
  },
  {
    'title' : 'Intelligent form fields & rich data types',
    'desc' : [
      {
        'value' : 'Unlike paper or spreadsheets, Device Magic lets you work with intelligent form fields.'
      },
      {
        'value' : 'Questions can be repeatedly answered, data can be validated to prevent errors, fields can be required, and drop-down options can automatically populate from your own live resources.'
      },
      {
        'value' : 'Work with rich data types. Collect images, capture signatures, draw sketches, scan barcodes, request passwords, and more.'
      }
    ],
    'image' : IntellegentForm,
    'leftContent' : true,
    'imagecenter' : true,
    'maxWidth' : 614,
  },
  {
    'title' : 'Dispatch forms to your team in the field',
    'desc' : [
      {
        'value' : 'Send forms with pre-populated data to notify employees in the field of new tasks.'
      },
      {
        'value' : 'Automate workflows so that the submission of one form triggers another form to be dispatched.'
      },
    ],
    'image' : DispatchForm,
    'leftContent' : false,
    'imagecenter' : true,
    'maxWidth' : 573,
  },
  {
    'title' : 'Automate entire business workflows',
    'desc' : [
      {
        'value' : 'Automate your data flow. There’s no limit to the number of destinations you can set up for each form.'
      },
      {
        'value' : 'Depending on how questions are answered, the routing of forms can automatically change. For example, data outside of established parameters can alert those who need critical information.'
      },
    ],
    'image' : AutomateEntire,
    'leftContent' : true,
    'imagecenter' : true,
    'maxWidth' : 604,
  },
  {
    'title' : 'Multiple delivery formats',
    'desc' : [
      {
        'value' : 'Once a form is completed, it is delivered in any combination of formats you want - and to any number of destinations.'
      },
      {
        'value' : 'Integrate data submissions directly into your systems of record. Or pull live data from your systems into form fields.'
      },
    ],
    'image' : MultipleDelivery,
    'leftContent' : true,
    'imagecenter' : false,
    'maxWidth' : 678,
    'isPink' : true,
  },
  {
    'title' : 'Complete your forms on any device',
    'desc' : [
      {
        'value' : 'With Device Magic web forms app, users can also complete forms on a desktop or laptop from within a web browser.'
      },
      {
        'value' : 'Your team will have access to the same forms and data collection capabilities whether they are using our web or mobile forms app.'
      },
    ],
    'image' : CompleteForm2,
    'leftContent' : false,
    'imagecenter' : false,
    'maxWidth' : 734,
    'isPink' : true,
    'flexEnd' : true,
  },
  {
    'title' : 'Control Form Access With User Groups',
    'desc' : [
      {
        'value' : 'Decide which devices can access which forms, so everyone has what they need, and nothing they don\'t.'
      },
      {
        'value' : 'Share new forms across all devices, or choose which devices to make them available to.'
      },
    ],
    'image' : ControlForm,
    'leftContent' : true,
    'imagecenter' : true,
    'maxWidth' : 645,
    'isPink' : true,
  },
  {
    'title' : 'Generate custom templates & reports',
    'desc' : [
      {
        'value' : 'Use your own custom PDF templates so that your team\'s form submissions retain your brand\'s look, feel, and corporate identity.'
      },
      {
        'value' : 'Leverage custom Microsoft Excel templates for enhanced data calculation, organization and charting capabilities.'
      },
      {
        'value' : 'Custom templates provide a more seamless and presentable experience when you don\'t simply want to send raw submission data.'
      },
    ],
    'image' : GenerateCustom,
    'leftContent' : false,
    'imagecenter' : false,
    'maxWidth' : 587,
    'isPink' : true,
  },
  {
    'title' : 'Geomapping & map overlays',
    'desc' : [
      {
        'value' : 'Superimpose an image on a map, and associate it with a location question to use directly in the field.'
      },
      {
        'value' : 'Assign a range of different points for data capture. Device Magic will automatically generate directions to each pin. For example, dispatch a form with mapped locations of specific equipment to be inspected.'
      },
    ],
    'image' : LandingGeomapping,
    'leftContent' : true,
    'imagecenter' : true,
    'maxWidth' : 776,
    'isPink' : true,
  },
]