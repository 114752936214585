import React from 'react'

// STYLES
import useStyles from './heroUseStyles'

// MUIS
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// COMPONENTS
import LandingButtonContained from 'components/LandingButton/LandingButton'

// ASSETS
import LandingIconHeroWifi from 'assets/images/icons/landing-icon-feature-wifi.png'
import LandingIconHeroMarker from 'assets/images/icons/landing-icon-feature-marker.png'
import LandingIconHeroUser from 'assets/images/icons/landing-icon-feature-user.png'
import LandingIconHeroSlice from 'assets/images/icons/landing-icon-feature-slice.png'

const Hero = () => {
  const classes = useStyles()

  return (
    <Container maxWidth={false} className={classes.root}>
      {/* SUBTITLE */}
      <Typography variant='body1' className={classes.subtitle}>
        Product Features
      </Typography>

      {/* TITLE */}
      <Typography variant='h2' className={classes.title}>
        Comprehensive features for professional teams
      </Typography>

      {/* DESCRIPTION */}
      <Typography variant='body1' className={classes.description}>
        Worx.id may be known for our ease-of-use, but that doesn’t mean we’re a lightweight product. Our customization options and tools ensure that we work with your advanced needs. With powerful integrations, strict data security, and 24/7 support, our application is built for the demands of professional teams.
      </Typography>

      {/* BUTTON */}
      <Box className={classes.buttonWrap}>
        <LandingButtonContained className={classes.buttonCustom}>
          Try It Free
        </LandingButtonContained>
      </Box>

      {/* ICON WIFI */}
      <Box
        component='img'
        src={LandingIconHeroWifi}
        className={classes.iconWifi}
      />

      {/* ICON MARKER */}
      <Box
        component='img'
        src={LandingIconHeroMarker}
        className={classes.iconMarker}
      />

      {/* ICON USER */}
      <Box
        component='img'
        src={LandingIconHeroUser}
        className={classes.iconUser}
      />

      {/* ICON USER */}
      <Box
        component='img'
        src={LandingIconHeroSlice}
        className={classes.iconSlice}
      />
    </Container>
  )
}

export default Hero