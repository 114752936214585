// MUIS STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    padding: '120px 140px'
  },
  itemWrapper: {
    alignItems: 'center',
    marginBottom: 120,
    '&:last-child': {
      marginBottom: 0
    }
  },
  contentTitle: {
    fontSize: 40,
    fontWeight: 700,
    lineHeight: 1.45,
    marginBottom: 25.58
  },
  contentDescription: {
    fontFamily: ['Inter', 'Roboto', 'sans-serif'].join(','),
    lineHeight: 1.5,
    fontSize: 16,
    marginBottom: 45
  },
  contentButton: {
    padding: 0,
    fontSize: 16
  },
  image: {
    width: '100%'
  }
}))

export default useStyles