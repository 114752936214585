// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  mainContainer : {
    width : '100%',
    padding : '120px 0',
    background : 'black',
    display : 'flex',
    flexDirection  : 'column',
  },
  topContentContainer : {
    margin : '0px 145px',
    marginBottom : 93,
    display : 'flex',
    flexDirection : 'row',
    justifyContent : 'space-between',
    alignItems : 'center'

  },
  logoCompany : {
    width : 150,
    cursor : 'pointer'
  },
  textLink : {
    color : 'white',
    fontWeight : 400,
    fontSize : 16,
    fontFamily : [ 
      'Inter', 'Roboto', 'sans-serif',
    ].join(','),
  },
  link : {
    color : 'white'
  },
  gridContainer : {
    width : '100%'
  },
  gridFlex : {
    display : 'flex',
    justifyContent : 'flex-end'
  },
  bottomContainer : {
    margin : '0px 145px',
    display : 'flex',
    justifyContent : 'space-between'
  },
  textBottom : {
    color : 'white',
    fontWeight : 400,
    fontSize : 16,
    fontFamily : [ 
      'Inter', 'Roboto', 'sans-serif',
    ].join(','),
  },
  icon : {
    color : 'white',
    cursor : 'pointer',
    marginLeft : 25,
    width : 20,
    height : 20
  }
}))

export default useStyles
