// MUIS STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  appBarRoot: {
    background: '#FFFFFF',
    boxShadow: 'none',
    color: theme.palette.text.primary,
    padding: '25px 140px',
    fontFamily: theme.typography.fontFamily,
    borderBottom: `3px solid ${theme.palette.primary.main}`
  },
  toolbar: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    padding: 0,
    minHeight: '38px'
  },
  logoWrap: {
    flex: '0 1 auto',
    height: 32.19
  },
  listMenuWrap: {
    flex: '1 1 auto',
    padding: 0,
    display: 'flex',
    justifyContent: 'center'
  },
  buttonWrap: {
    flex: '0 1 auto'
  },
  logo: {
    width: 109,
    height: 32.19,
    cursor: 'pointer'
  },
  listButton: {
    display: 'flex',
    padding: 0
  },
  listButtonItem: {
    flex: '0 1 auto',
    padding: '0 20px 0 0',
    '&:last-child': {
      padding: 0
    }
  },
  buttonOutlinedCustom: {
    whiteSpace: 'nowrap'
  },
  listMenu: {
    padding: 0,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  listMenuItem: {
    flex: '0 1 auto',
    width: 'auto',
    padding: '4px 60px 4px 0',
    '&:last-child': {
      padding: '4px 0 4px 0',
    }
  },
  listMenuLink: {
    borderBottom: '1px solid transparent',
    fontSize: 14,
    fontWeight: 700,
    padding: '5px 0',
    lineHeight: '14px',
    '&.active': {
      borderBottom: `1px solid ${theme.palette.primary.main}`
    },
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  },
}))

export default useStyles