import React from 'react'

// STYLES
import useStyles from './reasonToUseStyles'

// MUIS
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'

// ASSETS
import LandingReasonToUse from 'assets/images/pictures/landing-reason-to-use.svg'

const ReasonToUse = () => {
  const classes = useStyles()

  const dataListCount = [
    {
      total: '50 %',
      description: 'Reduction in data entry errors.'
    },
    {
      total: '10 hrs',
      description: 'Avg. administrative time saved/week'
    },
    {
      total: '11 hrs',
      description: 'Avg. field teams time saved/week'
    }
  ]

  return (
    <Container maxWidth={false} className={classes.root}>
      <Grid container spacing={0} className={classes.wrapper}>
        {/* IMAGE */}
        <Grid item lg={6} className={classes.imageWrap}>
          <Box
            component='img'
            src={LandingReasonToUse}
            className={classes.image}
          />
        </Grid>

        {/* CONTENT */}
        <Grid item lg={6} className={classes.contentWrap}>
          <Box className={classes.content}>
            {/* TITLE */}
            <Typography variant='h2' className={classes.contentTitle}>
              Why mobile form system?
            </Typography>

            {/* DESCRIPTION */}
            <Typography variant='body1' component='p' className={classes.contentDescription}>
              Mobile forms eliminate unnecessary administrative time. Let's reduce the data entry errors, incomplete forms, and operating costs that hurt your business’s bottom line.
            </Typography>

            {/* COUNT */}
            <List className={classes.listCount}>
              {dataListCount && dataListCount.map((item, index) => (
                <ListItem className={classes.listCountItem} key={index}>
                  {/* TOTAL */}
                  <Typography className={classes.listCountTotal}>
                    {item.total}
                  </Typography>
  
                  {/* DESCRIPTION */}
                  <Typography className={classes.listCountDescription}>
                    {item.description}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ReasonToUse