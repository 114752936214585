import PropTypes from 'prop-types'

// MUIS
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

// STYLES
import useStyles from './landingTextFieldUseStyles'

const LandingTextField = (props) => {
  const { 
    className,
    inputLabel,
    value,
    onChange,
    type,
    name,
    ...otherProps
  } = props

  const classes = useStyles()

  return (
    <FormControl 
      className={className}
      {...otherProps}
    >
      {/* LABEL */}
      <Typography 
        className={classes.inputLabel}
        variant='subtitle1'
      >
        {inputLabel}
      </Typography>

      {/* INPUT */}
      <TextField
        className={classes.input}
        onChange={onChange}
        value={value}
        type={type}
        name={name}
        required
      />
    </FormControl>
  )
}

LandingTextField.defaultProps = {
  className: '',
  inputLabel: '',
}

LandingTextField.propTypes = {
  className: PropTypes.string,
  inputLabel: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  name : PropTypes.string,
}

export default LandingTextField