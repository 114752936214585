export const dataContentLeft = [
  {
    title: 'Drag-and-Drop Form Builder',
    caption: null
  },
  {
    title: 'Unlimited Forms & Submissions',
    caption: null
  },
  {
    title: 'Unlimited Storage',
    caption: null
  },
  {
    title: 'Works Offline',
    caption: null
  },
  {
    title: 'Basic Submission Data Formats',
    caption: 'Plain Text, Images, MS Word, & Customizable PDFs'
  },
  {
    title: 'Advanced Form Destinations',
    caption: 'Email, Amazon S3, Box, Dropbox, Evernote, Google Drive, MS OneDrive, Slack'
  },
  {
    title: 'Intelligent Workflows',
    caption: 'Input Validation, Conditional and Repeat Fields, & Intelligent Submission Routing'
  },
  {
    title: 'Custom Branding',
    caption: null
  },
  {
    title: 'Live Support & Help Center',
    caption: null
  }
]

export const dataContentRight = [
  {
    title: 'Advanced Submission Formats',
    caption: 'Excel, JSON, and XML'
  },
  {
    title: 'Enterprise Destinations',
    caption: 'Zapier, Geotab, Salesforce, Sharefile, & SQL'
  },
  {
    title: 'Live Data Resources',
    caption: 'Pull Live Data From Your Google Drive or SQL Databases'
  },
  {
    title: 'Dispatches',
    caption: 'Send Pre-Populated Forms Directly To Team Members For Completion'
  },
  {
    title: 'Enhanced Security',
    caption: null
  },
  {
    title: 'Worx.id API',
    caption: null
  }
]