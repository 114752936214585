import React from 'react'
import { useNavigate } from 'react-router-dom'

// ASSETS
import LandingHomeHero from 'assets/images/pictures/landing-home-hero.png'

// STYLES
import useStyles from './heroUseStyles'

// MUIS
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// COMPONENTS
import LandingButtonContained from 'components/LandingButton/LandingButton'


const Hero = () => {
  const classes = useStyles()
  const navigate = useNavigate()

  const handleButtonClick = () => {
    navigate('#')
  }

  return (
    <Container className={classes.root} maxWidth={false}>
      <Grid container className={classes.contentWrap}>
        {/* DESCRIPTION */}
        <Grid item lg={7}>
          <Box className={classes.descriptionWrap}>
            {/* TITLE */}
            <Typography variant='h1' className={classes.heroTitle}>
              Mobile Form System For Professional Field Workers
            </Typography>

            {/* DESCRIPTION */}
            <Typography variant='body1' className={classes.heroDescription}>
              A hassle-free mobile data collection and workforce management for any business and professional teams.
            </Typography>

            {/* BUILD ACCOUNTBUTTON */}
            <LandingButtonContained
              onClick={handleButtonClick}
              className={classes.heroButton}
            >
              Build Your Account
            </LandingButtonContained>
          </Box>
        </Grid>

        {/* IMAGE */}
        <Grid item lg={5}>
          <Box
            component='img'
            src={LandingHomeHero}
            className={classes.heroImage}
          />
        </Grid>
      </Grid>
    </Container>
  )
}

export default Hero