import React from 'react'

// STYLES
import useStyles from './featuresUseStyles'

// MUIS
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'

// COMPONENTS
import Content from './Content'

// DATA
import dataFeatures from './dataFeatures'

const Features = () => {
  const classes = useStyles()

  return (
    <Container className={classes.root} maxWidth={false}>
      {/* LIST CONTENT */}
      <Box className={classes.listContent}>
        {/* CONTENT */}
        {dataFeatures && dataFeatures.map((item, index) => (
          <Content
            key={index}
            maxWidthContent={item.maxWidthContent}
            srcImage={item.srcImage}
            title={item.title}
            description={item.description}
            positionContent={item.positionContent}
            linkLearnMore={item.linkLearnMore}
          />
        ))}
      </Box>
    </Container>
  )
}

export default Features