// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  mainContainer : {
    height : '100%',
    width : '100%',
    display : 'flex',
    flexDirection : 'column'
  },
}))

export default useStyles
