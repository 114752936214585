// COLORS
import { colorsLanding } from 'constants/colors'

// MUIS STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: colorsLanding.cyan,
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    padding: '120px 140px'
  },
  title: {
    fontSize: 40,
    fontWeight: 700,
    maxWidth: 600,
    margin: '0 auto',
    textAlign: 'center',
    letterSpacing: '0.0015em',
    lineHeight: 1.5,
    marginBottom: 80
  },
  buttonWrap: {
    textAlign: 'center'
  },
  buttonCustom: {
    fontSize: 18,
    padding: '10.5px 50px',
    lineHeight: 1.4
  }
}))

export default useStyles