// MUI STYLES
import { makeStyles } from '@mui/styles'

// CONSTAN
import { colorsLanding } from 'constants/colors'

const useStyles = makeStyles((theme) => ({
  mainContainer : {
    borderBottom : 'solid 3px black'
  },
  contentLeft : {
    background : theme.palette.primary.main,
    paddingLeft : 140,
    paddingRight : 74,
    height : 618,
    display : 'flex',
    alignItems : 'flex-start',
    flexDirection : 'column',
    justifyContent : 'center'
  },
  contentRight : {
    background : theme.palette.primary.main,
    paddingLeft : 80,
    paddingRight : 134,
    height : 618,
    display : 'flex',
    alignItems : 'flex-start',
    flexDirection : 'column',
    justifyContent : 'center'
  },
  title : {
    fontWeight : 70,
    fontSize : 40,
    lineHeight : '59px',
    marginBottom : 40,
  },
  value : {
    '& .MuiTypography-root' : {
      fontSize : 16,
      fontWeight : 400,
      fontFamily  : [ 
        'Inter', 'Roboto', 'sans-serif',
      ].join(','),
    }
  },
  valueIcon : {
    color : colorsLanding.portage,
    width : '13px',
    height : '13px'
  },
  valueListIcon : {
    minWidth : '32px'
  },
  image : {
    width : '100%'
  },
  imageCenter : {
    width : '100%',
    display : 'flex',
    justifyContent : 'center',
    alignItems : 'center',
  },
  imageEnd : {
    width : '100%',
    display : 'flex',
    alignItems : 'end',
  }
}))

export default useStyles
