import React from 'react'
import PropTypes from 'prop-types'

// MUIS
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

// STYLES
import useStyles from '../benefitsUseStyles'

const CardBenefits = (props) => {
  const {
    srcIcon,
    title,
    description
  } = props

  const classes = useStyles()
  
  return (
    <Box className={classes.cardBenefits}>
      {/* ICON */}
      <Box
        component='img'
        src={srcIcon}
        className={classes.cardBenefitsIcon}
      />

      {/* TITLE */}
      <Typography variant='h6' className={classes.cardBenefitsTitle}>
        {title}
      </Typography>

      {/* DESCRIPTION */}
      <Typography variant='body1' className={classes.cardBenefitsDescription}>
        {description}
      </Typography>
    </Box>
  )
}

CardBenefits.propTypes = {
  srcIcon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string
}

export default CardBenefits