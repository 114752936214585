// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  mainContainer : {
    height : '100%',
    width : '100%',
    display : 'flex'
  },
  rightContent : {
    background : '#8D312F',
    height : '100%',
    width : '100%',
  },
  leftContent : {
    padding : '90px 140px',
    borderRight : 'solid 3px black',
    display : 'flex',
    justifyContent : 'center'
  },
  leftContainer : {
    maxWidth :754,
    width : '100%'
  },
  logoCompany : {
    width : 110,
    height : 32,
    cursor : 'pointer'
  },
  logoContainer : {
    display : 'flex',
    justifyContent : 'space-between',
    alignItems : 'center',
    width : '100%',
    marginBottom : 94,
  },
  textLink : {
    fontFamily : 'space-mono'
  },
  titleContent : {
    fontSize : 56,
    fontWeight : 700,
    lineHeight : '84px',
    marginBottom : 40,
  },
  checBoxContainer : {
    marginTop : 32,
  },
  checkBox : {
    color : 'black',
    marginRight : 15,
    padding : 0,
    paddingLeft : 9,
  },
  submitButtonContainer : {
    display : 'flex',
    flexDirection : 'column',
    alignItems : 'center',
    marginBottom : 30,
  },
  checkBoxText : {
    '& .MuiTypography-root' : {
      maxWidth : 597,
      fontSize : 16,
      fontWeight : 400,
      fontFamily: [ 
        'Inter', 'Roboto', 'sans-serif',
      ].join(','),
    },
    
    display : 'flex',
    alignItems : 'flex-start'
  },
  submitBtn : {
    marginTop : 56,
    width : '100%',
  }
}))

export default useStyles
