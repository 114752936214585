import React from 'react'

// STYLES
import useStyles from './openingUseStyles'

// MUIS
import { Box,Typography } from '@mui/material'

const Opening = (props)=> {
  const classes = useStyles()
  const {objectTypeContent} = props
  
  return (
    <>
      {/* HERO SECTION */}
      <Box className={classes.heroContainer}>
        {/* HERO TITLE */}
        <Typography align='center' className={classes.heroTitle}>
          {objectTypeContent.title}
        </Typography>

        {/* EFFECTIVE DATE */}
        <Typography align='center' className={classes.heroValue}>
          Effective Date: {objectTypeContent.effective_date}
        </Typography>
      </Box>

      {/* OPENING CONTENT */}
      <Box className={classes.contentContainer}>
        <Typography className={classes.contentText}>
          {objectTypeContent.opening.replace(/Lacak.io/g, 'Worx.id') }
        </Typography>
      </Box>
    </>
  )
}

export default Opening