import React from 'react'

// MUIS
import { Box, Typography } from '@mui/material'

// STYLES 
import useStyles from './informationUseStyles'

const Information = () => {
  const classes = useStyles()

  return (
    <Box className={classes.mainContainer}>
      {/* TITLE */}
      <Typography align='center' className={classes.title}>
        Eget pretium vel quam at.
      </Typography>

      {/* VALUE */}
      <Typography align='center' className={classes.value}>
        Use the #1 platform engineered to excel on your most important industry tasks. Device Magic helps businesses run better.
      </Typography>
    </Box>
  )
}

export default Information