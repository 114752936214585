import PropTypes from 'prop-types'

// MUIS
import Button from '@mui/material/Button'

// STYLES
import useStyles from './landingButtonUseStyles'

const LandingButtonContained = (props) => {
  const { 
    children,
    className,
    color, 
    sx,
    variant,
    ...otherProps
  } = props

  const classes = useStyles()

  return (
    <Button
      className={`${classes.root} ${className}`}
      color={color}
      disableElevation
      variant={variant}
      sx={{
        border: variant === 'contained' ? '2px solid black' : 'none',
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </Button>
  )
}

LandingButtonContained.defaultProps = {
  className: '',
  color: 'primary',
  sx: {},
  variant: 'contained',
}

LandingButtonContained.propTypes = {
  color: PropTypes.oneOf([ 'primary', 'secondary' ]),
  className: PropTypes.string,
  children: PropTypes.node,
  sx: PropTypes.object,
  variant: PropTypes.oneOf([ 'contained', 'text' ]),
}

export default LandingButtonContained