import PropTypes from 'prop-types'

// MUIS
import Link from '@mui/material/Link'

// STYLES
import useStyles from './landingLinkUseStyles'

const LandingLink = (props) => {
  const { 
    children,
    className,
    ...otherProps
  } = props

  const classes = useStyles()

  return (
    <Link
      className={`${classes.root} ${className}`}
      underline='hover'
      {...otherProps}
    >
      {children}
    </Link>
  )
}

LandingLink.defaultProps = {
  className: '',
  sx: {},
}

LandingLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

export default LandingLink