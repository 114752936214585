import React from 'react'
import PropTypes from 'prop-types'

// STYLES
import useStyles from '../plansUseStyles'

// MUIS
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'

// ICONS
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

// COMPONENTS
import LandingButtonContained from 'components/LandingButton/LandingButton'

const ListChecked = (props) => {
  const {
    contentTitle,
    contentDescription,
    listData,
    buttonColor,
    buttonText,
    onClickButton,
  } = props
  const classes = useStyles()
  
  return (
    <>
      {/* TITLE */}
      {contentTitle && (
        <Typography variant='h4' className={classes.contentTitle}>
          {contentTitle}
        </Typography>)}

      {/* DESCRIPTION */}
      {contentDescription && (
        <Typography variant='body1' className={classes.contentDescription}>
          {contentDescription}
        </Typography>)}

      {/* LIST */}
      <List className={classes.listPlans}>
        {listData && listData.map((item, index) => (
          <ListItem className={classes.listPlansItem} key={index}>
            {/* ICON */}
            <Box className={classes.iconWrap}>
              <CheckCircleIcon className={classes.iconChecked}/>
            </Box>

            <Box className={classes.valueWrap}>
              {/* TITLE */}
              <Typography variant='h6' className={classes.listTitle}>
                {item.title}
              </Typography>

              {/* CAPTION */}
              {item.caption && (<Typography variant='h6' className={classes.listDescription}>
                {item.caption}
              </Typography>)}
            </Box>
          </ListItem>
        ))}
      </List>

      {/* BUTTON */}
      <Box className={classes.buttonWrap}>
        <LandingButtonContained
          onClick={onClickButton}
          color={buttonColor}
          className={classes.buttonCustom}
        >
          {buttonText}
        </LandingButtonContained>
      </Box>
    </>
  )
}

ListChecked.propTypes = {
  contentTitle: PropTypes.string,
  contentDescription: PropTypes.string,
  listData: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    caption: PropTypes.string,
  })),
  buttonColor: PropTypes.string,
  buttonText: PropTypes.string,
  onClickButton: PropTypes.func, 
}

export default ListChecked