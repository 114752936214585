import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// ASSETS
import LogoWorxBlack from 'assets/images/logos/company-worx-black.png'

// STYLES
import useStyles from './landingAppBarUseStyles'

// MUIS
import MuiAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'

// COMPONENTS
import LandingButton from '../LandingButton/LandingButton'
import LandingLink from 'components/LandingLink/LandingLink'

// UTILS
import { isNavigationItemActive } from './utils'

const AppBar = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()

  const navigationList = [
    {
      title: 'Features',
      path: '/features'
    },
    {
      title: 'Solution',
      path: '/solution'
    },
    {
      title: 'Pricing',
      path: '/pricing'
    },
  ]

  const buttonList = [
    {
      name: 'Schedule A Demo',
      path: '/schedule-a-demo',
      variant: 'outlined',
      color: 'secondary'
    },
    {
      name: 'Try It Free',
      path: '/try-it-free',
      variant: 'contained',
      color: 'primary'
    }
  ]

  return (
    <MuiAppBar className={classes.appBarRoot} position='fixed'>
      <Toolbar className={classes.toolbar}>
        {/* LOGO */}
        <Box className={classes.logoWrap}>
          <Box
            component='img'
            src={LogoWorxBlack}
            className={classes.logo}
            onClick={() => navigate('/')}
          />
        </Box>

        {/* LIST MENU */}
        <Box className={classes.listMenuWrap}>
          <List className={classes.listMenu}>
            {navigationList && navigationList.map((item, index) => (
              <ListItem key={index} className={classes.listMenuItem}>
                <LandingLink
                  className={
                    isNavigationItemActive(location.pathname, item.path)
                      ? `${classes.listMenuLink} active`
                      : classes.listMenuLink
                  }
                  onClick={() => navigate(item.path)}
                >
                  {item.title}
                </LandingLink>
              </ListItem>
            ))}
          </List>
        </Box>

        {/* BUTTON APPBAR */}
        {buttonList && (<Box className={classes.buttonWrap}>
          {/* LIST BUTTON */}
          <List className={classes.listButton}>
            {buttonList.map((item, index) => (
              <ListItem key={index} className={classes.listButtonItem}>
                <LandingButton
                  variant={item.contained}
                  color={item.color}
                  onClick={() => navigate(item.path)}
                  className={classes.buttonOutlinedCustom}
                >
                  {item.name}
                </LandingButton>
              </ListItem>
            ))}
          </List>
        </Box>)}
      </Toolbar>
    </MuiAppBar>
  )
}

export default AppBar